.ocs-quick-search-content {
  flex: 1;
  overflow-y: hidden;
  border-top: 1px solid var(--pf-v5-global--BorderColor--100);
  &__list {
    width: 40%;
    height: 100%;
  }
  &__details {
    background-color: var(--pf-v5-global--BackgroundColor--100);
    width: 60%;
    overflow-y: auto;
    height: 100%;
    padding-right: var(--pf-v5-global--spacer--md);
  }
}
