.odc-catalog-badges {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--pf-v5-global--spacer--sm);

  &__label {
    margin: var(--pf-v5-global--spacer--xs) var(--pf-v5-global--spacer--xs)
      var(--pf-v5-global--spacer--xs) 0;
  }
}
