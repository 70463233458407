.odc-catalog-tile .catalog-tile-pf-icon {
  padding: var(--pf-v5-global--spacer--xs);
  border-radius: var(--pf-v5-global--BorderRadius--sm);
  background: var(--pf-v5-global--palette--white);
  max-width: 100px;
  height: 40px;
}

.odc-catalog-tile>.catalog-tile-pf-body {
  display: flex;
  flex-direction: column;
}

.odc-catalog-tile>.catalog-tile-pf-body>.catalog-tile-pf-description {
  flex: 1 1 auto;
}

