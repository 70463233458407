.dps-icons__green-check-icon {
  fill: var(--pf-v5-global--palette--green-500) !important;
}

.dps-icons__red-exclamation-icon {
  fill: var(--pf-v5-global--danger-color--100) !important;
}

.dps-icons__yellow-exclamation-icon {
  fill: var(--pf-v5-global--warning-color--100) !important;
}

.dps-icons__blue-info-icon {
  fill: var(--pf-v5-global--palette--blue-300) !important;
}

