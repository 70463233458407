// [converter] $parent hack
@mixin responsive-visibility($parent) {
  #{$parent} {
    display: block !important;
  }
  table#{$parent}  { display: table !important; }
  tr#{$parent}     { display: table-row !important; }
  th#{$parent},
  td#{$parent}     { display: table-cell !important; }
}

// [converter] $parent hack
@mixin responsive-invisibility($parent) {
  #{$parent} {
    display: none !important;
  }
}

@mixin box-shadow($shadow...) {
  box-shadow: $shadow;
}

// Text overflow
// Requires inline-block or block for proper styling
@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
