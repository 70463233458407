.co-cloud-shell-tab__body {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.co-cloud-shell-tab__header {
  background-color: var(--pf-v5-global--BackgroundColor--200);
  align-items: center;
  display: flex;
  flex-shrink: 0;
  min-height: var(--pf-v5-global--target-size--MinHeight);
}

.co-cloud-shell-tab__header-text {
  padding: 0 var(--pf-v5-global--spacer--md);
}

