ul.pf-v5-c-list:not(.pf-m-inline).co-detail-property-list {
  list-style: none;
  padding-left: 0;
}

ul.pf-v5-c-list:not(.pf-m-inline).co-detail-property-list li+li {
  margin-top: 0;
}

.co-detail-property-list__item-title {
  color: var(--pf-v5-global--Color--200);
}

