$global-notification-text: $pf-v5-color-white;

.co-global-notification {
  background-color: $pf-v5-color-blue-400;
  color: $global-notification-text;
  padding: 4px $pf-v5-global-gutter;
  text-align: center;

  @media (min-width: $pf-v5-global--breakpoint--md) {
    padding: 6px $pf-v5-global-gutter--md;
  }

  + .co-global-notification {
    margin-top: 1px;
  }

  a {
    color: $global-notification-text;
    cursor: pointer;
    text-decoration: underline;
  }

  button {
    color: $global-notification-text !important;
  }
}

.co-global-notification__text {
  margin: 0;
}

.co-global-notification__impersonate-name {
  font-weight: var(--pf-v5-global--FontWeight--bold);
}

.co-global-notification__impersonate-kind {
  font-weight: var(--pf-v5-global--FontWeight--bold);
  padding-right: 20px;
}
