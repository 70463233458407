.co-cloud-shell-drawer__heading {
  padding-left: var(--pf-v5-global--spacer--md);
}

.co-cloud-shell-drawer__body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.co-cloud-shell-drawer__body .pf-v5-c-tabs {
  flex-shrink: 0;
}

