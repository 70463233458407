.co-resource-list__item {
  height: 100%;
  position: relative;
  transition: all 0.25s;

  .dropdown-kebab-pf {
    position: absolute;
    right: 15px;
    top: 7px;
  }
}

.co-resource-list__item--expanded {
  display: inline-block;
  margin-top: 10px;
}
.compaction-btn {
  margin-right: 0 !important;
}
