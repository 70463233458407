.ocs-getting-started-card__title-icon {
  margin-right: var(--pf-v5-global--spacer--sm);
}

.ocs-getting-started-card small {
  color: var(--pf-v5-global--Color--200);
}

.ocs-getting-started-card__list>ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.ocs-getting-started-card__list>ul>li .pf-v5-c-skeleton {
  margin: var(--pf-v5-c-simple-list__item-link--PaddingTop) var(--pf-v5-c-simple-list__item-link--PaddingRight) var(--pf-v5-c-simple-list__item-link--PaddingBottom) var(--pf-v5-c-simple-list__item-link--PaddingLeft);
}

.ocs-getting-started-card a>svg,
.ocs-getting-started-card button>svg {
  margin-left: var(--pf-v5-global--spacer--sm);
}

