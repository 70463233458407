.ocs-radio-group-field {
  padding-left: var(--pf-v5-global--spacer--sm);
}

.ocs-radio-group-field input[type=radio] {
  margin-top: 0;
}

.ocs-radio-group-field:not(.ocs-radio-group-field--inline) .pf-v5-c-radio {
  padding-bottom: var(--pf-v5-global--spacer--md);
}

.ocs-radio-group-field:not(.ocs-radio-group-field--inline) .pf-v5-c-radio:last-of-type {
  padding-bottom: 0;
}

.ocs-radio-group-field:not(.ocs-radio-group-field--inline) .ocs-radio-group-field__children {
  padding-top: var(--pf-v5-global--spacer--sm);
}

.ocs-radio-group-field--inline {
  display: flex;
  align-items: center;
}

.ocs-radio-group-field--inline>.pf-v5-c-form__group-label {
  padding-bottom: 0;
  margin-right: var(--pf-v5-global--spacer--md);
}

.ocs-radio-group-field .pf-v5-c-radio__description {
  width: 100%;
}

