.pod-ring__center-text tspan {
  font-size: 14px !important;
  fill: var(--pf-v5-global--Color--200) !important;
}

.pod-ring__center-text:not(.pod-ring__long-text) tspan:first-of-type {
  font-size: var(--pf-v5-chart-global--FontSize--2xl) !important;
  fill: var(--pf-v5-global--Color--100) !important;
}

.pod-ring__center-text--reversed tspan {
  font-size: 14px !important;
  fill: var(--pf-v5-global--Color--200) !important;
}

.pod-ring__center-text--reversed tspan:first-of-type {
  fill: var(--pf-v5-global--Color--100) !important;
}

