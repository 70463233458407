.oc-perspective-nav {
  --pf-v5-c-nav__list--PaddingTop: 0;
  --pf-v5-c-nav__list--PaddingBottom: 0;
}

.oc-perspective-nav .pf-v5-c-nav__section:first-child .pf-v5-c-nav__section-title {
  --pf-v5-c-nav__section-title--PaddingTop: 0;
  border: none;
}

.oc-perspective-nav .pf-v5-c-nav__section.no-title {
  border-bottom: 1px solid var(--pf-v5-global--BackgroundColor--dark-200);
  padding-bottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__section--section--MarginTop: var(--pf-v5-global--spacer--sm);
}

.oc-perspective-nav .pf-v5-c-nav__section.no-title .pf-v5-c-nav__section-title {
  --pf-v5-c-nav__section-title--PaddingBottom: 0;
}

.oc-perspective-nav .pf-v5-c-nav__section.no-title:last-child {
  border: none;
}

.oc-perspective-nav--dragging {
  background-color: var(--pf-v5-global--Color--200) !important;
}

.oc-perspective-nav--dragging .pf-v5-c-nav__link {
  background-color: var(--pf-v5-global--Color--200) !important;
}

.oc-perspective-nav--dragging .oc-pinned-resource__unpin-button,
.oc-perspective-nav--dragging .oc-pinned-resource__drag-button {
  opacity: 0;
}

