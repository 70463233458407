.co-btn-file {
  overflow: hidden;
  position: relative;
  input[type='file'] {
    background: white;
    cursor: inherit;
    display: block;
    filter: alpha(opacity=0);
    font-size: 100px;
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    outline: none;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
    z-index: 2;
  }
}

.co-file-dropzone {
  position: relative;

  &__flex {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .co-file-dropzone-container {
    border: var(--pf-v5-global--BorderWidth--lg) solid var(--pf-v5-global--BorderColor--100);
    background: rgba(0, 0, 0, 0.3);
    bottom: 0;
    color: #fff;
    left: 0;
    margin: -3px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1001;
    &.co-file-dropzone--drop-over {
      border-color: $pf-v5-color-blue-300;
      color: $pf-v5-color-blue-300;
    }
  }
}

.co-file-dropzone__textarea {
  font-family: var(--pf-v5-global--FontFamily--monospace);
  min-height: 300px;
  width: 100%;
}

.co-file-dropzone__drop-text {
  font-size: 20px;
  font-weight: var(--pf-v5-global--FontWeight--bold);
  left: 50%;
  margin-right: -50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
