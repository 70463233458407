.co-details-card__body {
  display: flex;
  flex-wrap: wrap;
}

.co-details-card__item-title {
  flex-basis: 100%;
}

.co-details-card__item-value {
  flex-basis: 100%;
  margin-bottom: 8px;
  overflow: hidden;
  overflow-wrap: break-word;
}

