.co-resource-sidebar-item {
  border-top: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  padding: ($line-height-computed * 0.5) 0 $line-height-computed;

  &:first-child {
    border-top: 0;
    padding-top: 0;
  }
}

.co-resource-sidebar-item__img {
  margin: $line-height-computed 0;
}
