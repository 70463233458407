:root {
  --pf-v5-global--FontSize--md: 14px;
  --pf-global--FontSize--md: 14px;
  --pf-v5-global--FontSize--sm: 13px;
  --pf-global--FontSize--sm: 13px;
}

.ocs-page-layout__content {
  padding: 1.5rem 1rem;
  background-color: var(--pf-v5-global--BackgroundColor--200);
  flex: 1;
}

@media (min-width: 1200px) {
  .ocs-page-layout__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.ocs-page-layout__content.is-dark {
  background: var(--pf-v5-global--BackgroundColor--100);
}

.ocs-page-layout__header {
  background-color: var(--pf-v5-global--BackgroundColor--light-100);
}

.ocs-page-layout__hint {
  padding-bottom: var(--pf-v5-global--spacer--md);
  padding: 0 1rem 1.5rem;
}

@media (min-width: 1200px) {
  .ocs-page-layout__hint {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.ocs-page-layout__title {
  padding: 0 1rem;
}

@media (min-width: 1200px) {
  .ocs-page-layout__title {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

