$co-m-horizontal-nav__menu-item-link-padding-lr: $pf-v5-global-gutter;

.co-m-horizontal-nav__menu {
  border-bottom: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  display: flex;
  flex-shrink: 0; // prevent collapse of tabs
  list-style: none;
  margin: 0;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  @media (min-width: $pf-v5-global--breakpoint--xl) {
    padding-left: ($pf-v5-global-gutter--md - $pf-v5-global-gutter);
  }
  &--within-sidebar {
    border-top: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
    margin-bottom: $pf-v5-global-gutter--md !important;
    margin-left: -$pf-v5-global-gutter;
    margin-right: -$pf-v5-global-gutter;
    overflow: visible; // so focus indicator is not clipped since these don't need to scroll
    @media (min-width: $pf-v5-global--breakpoint--xl) {
      margin-left: -$pf-v5-global-gutter--md;
      margin-right: -$pf-v5-global-gutter--md;
    }
    .co-m-horizontal-nav__menu-item {
      font-size: 16px;
      line-height: normal;
    }
  }
  &--within-overview-sidebar {
    margin-left: 0;
    margin-right: 0;
    padding-left: 5px;
  }
}

.co-m-horizontal-nav__menu-item {
  font-size: 18px;
  a,
  button {
    background: transparent;
    border: 0;
    color: var(--pf-v5-global--Color--200);
    display: block;
    padding: 6px $co-m-horizontal-nav__menu-item-link-padding-lr 8px;
    position: relative;
    text-decoration: none;
    &:hover,
    &:focus {
      color: var(--pf-v5-global--Color--100);
      text-decoration: none;
      &::after {
        background: var(--pf-v5-global--palette--black-400);
      }
    }
    &::after {
      bottom: 0;
      content: '';
      display: block;
      height: 2px;
      left: $co-m-horizontal-nav__menu-item-link-padding-lr;
      position: absolute;
      right: $co-m-horizontal-nav__menu-item-link-padding-lr;
    }
  }
  &.co-m-horizontal-nav-item--active {
    a,
    button {
      color: var(--pf-v5-global--link--Color);
      &::after {
        background: var(--pf-v5-global--link--Color);
      }
    }
  }
  &:last-child {
    @media (min-width: $pf-v5-global--breakpoint--md) {
      a,
      button {
        margin-right: $co-m-horizontal-nav__menu-item-link-padding-lr;
      }
    }
  }
}
