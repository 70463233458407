.co-import-yaml-results-page {
  height: 100%;
}

.co-import-yaml-results-page__footer {
  padding-top: 25px;
  text-align: center;
}

.co-import-yaml-results-page__main {
  margin: 0 $pf-v5-global-gutter;
  @media (min-width: $screen-sm-min) {
    margin: $pf-v5-global-gutter--md;
    max-width: 600px;
    min-width: 375px;
    width: 100%;
  }
}

.co-import-yaml-status {
  margin-bottom: 20px;
}
