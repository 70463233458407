.co-markdown-view.is-empty {
  color: #999;
}

.co-markdown-view table {
  display: block;
  margin-bottom: 11.5px;
  overflow-x: auto;
}

.co-markdown-view td,
.co-markdown-view th {
  border-bottom: 1px solid #ededed;
  padding: 10px;
  vertical-align: top;
}

.co-markdown-view th {
  padding-top: 0;
}

