.ocs-getting-started-card {
  &__title-icon {
    margin-right: var(--pf-v5-global--spacer--sm);
  }

  small {
    color: var(--pf-v5-global--Color--200);
  }

  &__list {
    // Remove default list styles which are globally resetted in the PatternFly samples
    // but not in the SimpleList. https://www.patternfly.org/v4/components/simple-list
    > ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      > li {
        // Add spacing to the skeleton similar to the SimpleListItem
        .pf-v5-c-skeleton {
          margin: var(--pf-v5-c-simple-list__item-link--PaddingTop)
            var(--pf-v5-c-simple-list__item-link--PaddingRight)
            var(--pf-v5-c-simple-list__item-link--PaddingBottom)
            var(--pf-v5-c-simple-list__item-link--PaddingLeft);
        }
      }
    }
  }

  a > svg,
  button > svg {
    margin-left: var(--pf-v5-global--spacer--sm);
  }
}
