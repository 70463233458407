.co-suggestion-box {
  background-color: var(--pf-v5-global--BackgroundColor--100);
  z-index: 5;
  width: 100%;
  @media (max-width: $screen-xs-min) {
    max-width: calc(100% - 95px);
  }
  @media (min-width: $screen-xs-min) and (max-width: $screen-sm-min) {
    max-width: 200px;
  }
}

.co-suggestion-box__suggestions {
  background-color: var(--pf-v5-global--BackgroundColor--100);
  /* To make the suggestion box hover on top of the table */
  margin-bottom: -19em;
  display: flex;
  flex-direction: column;
  @media (min-width: $screen-xs-min) and (max-width: $screen-sm-min) {
    max-width: 200px;
  }
}

.co-suggestion-box__suggestions--shadowed {
  box-shadow: var(--pf-v5-global--BoxShadow--md);
  padding: var(--pf-v5-global--spacer--sm);
}

.co-suggestion-line {
  border: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--300);
  background-color: var(--pf-v5-global--palette--black-150);
  border-radius: 12px;
  overflow: hidden;
  display: inline;
  align-self: flex-start;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - var(--pf-v5-global--spacer--sm));
  margin: 0.15rem 0;
}
