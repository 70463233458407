$co-external-link-padding-right: 15px;

.co-breadcrumb {
  padding-bottom: var(--pf-v5-global--spacer--sm);
}

.co-divider {
  margin-bottom: var(--pf-v5-global--spacer--lg);
  margin-top: var(--pf-v5-global--spacer--lg);
}

dl.co-inline {
  dd {
    margin-bottom: 1em;
  }
  dt {
    float: left;
    margin-right: 0.4em;
  }
}

.co-m-pane__body {
  margin: $pf-v5-global-gutter--md 0 0;
  padding: 0 $pf-v5-global-gutter $pf-v5-global-gutter--md;
  @media (min-width: $pf-v5-global--breakpoint--xl) {
    padding-left: $pf-v5-global-gutter--md;
    padding-right: $pf-v5-global-gutter--md;
  }
  &--full-height {
    height: 100%;
  }
  &--no-top-margin {
    margin-top: 0;
  }
  &--section-heading {
    padding-bottom: 0;
  }
  + .co-m-pane__body {
    border-top: 1px solid var(--pf-v5-global--BorderColor--100);
    margin-top: 0;
    padding-top: $pf-v5-global-gutter--md;
  }
}

.co-m-pane__createLink--no-title {
  margin-bottom: $pf-v5-global-gutter--md;
}

.co-m-pane__filter-bar,
.co-m-pane__help-text {
  margin: 20px $pf-v5-global-gutter;
  @media (min-width: $pf-v5-global--breakpoint--md) {
    margin-left: $pf-v5-global-gutter--md;
    margin-right: $pf-v5-global-gutter--md;
    margin-top: $pf-v5-global-gutter--md;
  }
}

.co-button-help-icon {
  padding: 0 5px; // override default left and right padding to bring help icon closer to text
}

.co-m-pane__filter-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .co-m-primary-action,
  .pf-v5-c-form-control {
    margin-bottom: 10px;
  }
}

.co-m-pane__filter-bar-group {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  min-width: 0;
}

.co-m-pane__filter-bar-group--filter {
  @media (min-width: $screen-xs-min) {
    flex: 1 0 auto;
    justify-content: flex-end;
  }
}

// Use to left align content filters that aren't using .pf-v5-c-toolbar
.co-m-pane__filter-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: $pf-v5-global-gutter--md;
}

.co-m-pane__filter-row-action {
  padding-top: $pf-v5-global-gutter;
  @media (min-width: $screen-xs-min) {
    padding-top: 0;
  }
}

.co-m-pane__heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: $pf-v5-global-gutter--md !important;
  &--baseline {
    align-items: baseline;
  }
  &--center {
    justify-content: center;
  }
  &--logo {
    align-items: center;
  }
  &--with-help-text {
    margin-bottom: 0 !important;
  }
}

.co-m-pane__heading-badge {
  @media (min-width: $screen-sm-min) {
    margin-left: var(--pf-v5-global--spacer--sm);
  }
}

.co-m-pane__heading-help-text {
  margin-bottom: $pf-v5-global-gutter--md !important;
}

.co-m-pane__heading-owner {
  display: block;
  font-size: $font-size-base;
  line-height: var(--pf-v5-global--LineHeight--md);
  padding-top: var(--pf-v5-global--spacer--sm);
  span {
    display: inline;
  }
}

.co-m-pane__heading-link {
  font-size: $font-size-base;
}

.co-no-bold {
  font-weight: var(--pf-v5-global--FontWeight--normal);
}

.cos-error-title {
  color: $pf-v5-color-red-100;
}

.co-section-heading {
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px 0;
}

.co-section-heading-secondary {
  font-size: 16px;
  margin: 30px 0;
}

.co-section-heading-tertiary {
  font-size: 16px;
  margin: 15px 0;
}

.co-switch-label {
  margin-bottom: 0;

  // TODO: remove when $font-size-base goes away or to 16px
  // and when override `label` font-weight style from _bootstrap-residual.scss is removed
  .pf-v5-c-switch__label {
    font-size: $font-size-base;
    font-weight: var(--pf-v5-global--FontWeight--normal);
  }
}

.co-table-container {
  overflow: auto;
  margin-bottom: 30px;
}

.co-m-pane__details {
  line-height: 1.66;
  // TODO: refactor so <dl>s have margin-bottom by default
  min-width: 0; // enable break-word
  white-space: normal; // override inheritence from co-detail-table
  .co-detail-table__row & {
    margin-bottom: 0;
  }
  dd {
    @include co-break-word;
  }
}

.co-m-pane__dropdown {
  margin-bottom: 20px;
}

.co-m-pane__explanation {
  color: $pf-v5-color-black-600;
  margin-bottom: 30px;
}

.co-m-pane__explanation--alt {
  margin-bottom: 20px;
}

.co-help-text {
  color: var(--pf-v5-global--Color--200);
}

.co-m-pane__explanation code {
  background-color: var(--pf-v5-global--BackgroundColor--200);
  color: $pf-v5-color-black-600;
}

.co-pre-line {
  white-space: pre-line;
}

.co-pre-wrap {
  white-space: pre-wrap;
}

// Prevent iOS phones from zooming on form inputs
@supports (-webkit-overflow-scrolling: touch) {
  // Target mobile Safari
  @media (max-width: $pf-v5-global--breakpoint--sm-max) {
    // Target phones
    input,
    select,
    .tag-item,
    textarea {
      font-size: 16px !important;
    }
  }
}

.co-error {
  color: $pf-v5-color-red-100;
  white-space: nowrap;
}

.error-message {
  color: var(--pf-v5-global--palette--white);
  padding: 2px 12px;
  background-color: #d64456;
}

.co-break-word {
  @include co-break-word;
}

// Prefer `co-break-word` in most cases. `co-break-all` should generally be reserved for URLs and long,
// unbroken identifiers.
.co-break-all {
  word-break: break-all;
}

// append external-link icon to <a> so that it doesn't wrap without text
// there must be no white space between the text and the closing </a> tag holding the pseudo element
.co-external-link::after {
  @include font-awesome-free-solid;
  content: fa-content($fa-var-external-link-alt);
  font-size: 75%;
  height: 16px;
  margin-left: 3px;
  margin-right: -15px; // width + margin-left
  position: relative;
  top: 0;
  width: 12px;
  .pf-v5-c-dropdown__menu-item & {
    color: $pf-v5-color-black-600;
    margin-left: 5px;
  }
  .pf-v5-c-nav__link & {
    color: $pf-v5-color-black-500;
    margin-left: 4px;
  }
}
.co-external-link {
  display: inline-block;
  padding-right: $co-external-link-padding-right;
}
// Enable break word within co-m-table-grid
.co-external-link--block {
  display: block;
}

.co-external-link-with-copy {
  &__icon {
    color: var(--pf-v5-global--link--Color);
    cursor: pointer;
    &:hover {
      color: var(--pf-v5-global--Color--300);
    }
  }
  &__externallinkicon {
    padding-left: var(--pf-v5-global--spacer--xs);
  }
  &__copyicon {
    margin-left: var(--pf-v5-global--spacer--md);
  }
}

// Prevent inline appended icons from wrapping separately without its associated text
.co-icon-nowrap {
  display: inline;
  white-space: nowrap;
}

.co-goto-arrow::after {
  @include font-awesome-free-solid;
  content: fa-content($fa-var-arrow-right);
  font-size: 75%;
  height: 16px;
  margin-left: 3px;
  margin-right: -15px; // width + margin-left
  position: relative;
  top: 0;
  width: 12px;
}

.co-icon-flex-child {
  flex: 0 0 auto;
  position: relative;
  top: 0.125em;
}

.co-line-clamp {
  @include co-line-clamp;
}

.co-sidebar-dismiss {
  padding: 0 5px;
}

// PatternFly's TextList lacks a plain variant
// see https://github.com/patternfly/patternfly-react/issues/8434
.co-text-list-plain {
  list-style: none !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.co-toolbar {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 5px;
}

.co-toolbar__item {
  max-width: 100%;
  padding: 5px 0;
  .co-toolbar__item-dropdown {
    max-width: 100%;
  }
}

.co-toolbar__group {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-width: 0;

  @media (max-width: $screen-xs-max) {
    flex-direction: column;
    justify-content: flex-end;
  }
}

.co-toolbar__group--left {
  justify-content: flex-start;
  .co-toolbar__item {
    padding-right: 15px;
  }
  @media (max-width: $screen-xs-max) {
    align-items: flex-start;
  }
}

.co-toolbar__group--alongside-kebab {
  flex: 1 1 0;
  flex-direction: row;
}

.co-toolbar__group--kebab {
  justify-content: flex-start;
}

.co-toolbar__group--right {
  justify-content: flex-end;
  @media (min-width: $screen-xs-min) {
    .co-toolbar__item {
      padding-left: 15px;
    }
  }
  @media (max-width: $screen-xs-max) {
    align-items: flex-end;
  }
}

.co-legend {
  border-bottom: 0;
  font-weight: var(--pf-v5-global--FontWeight--bold);
  font-size: $font-size-base;
  margin-bottom: 5px;

  &--nested {
    margin-top: 10px;
  }

  + .radio {
    margin-top: 0;
  }
}

.help-block {
  margin-bottom: 0;
}

// Note: include .co-select-to-copy to enable single click selection of full text
.co-truncate {
  @include co-truncate;
}

.co-select-to-copy {
  cursor: copy;
  user-select: all;
  -moz-user-select: all;
  -webkit-user-select: all;
}

.co-nowrap {
  white-space: nowrap;
}

.co-an-fade-in-out {
  transition-duration: compact(0.2s, false, false, false, false, false, false, false, false, false);
  transition-property: compact(
    opacity,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false
  );
  transition-timing-function: compact(
    linear,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false
  );
}

.co-m-radio-desc {
  margin-left: 20px;
}

.co-m-form-row {
  margin-bottom: 20px;
}

.co-m-form-row:last-child {
  margin-bottom: 0;
}

.co-m-form-col {
  max-width: 600px;
}

// Enable word-break and append pencil icon ::after so that it doesn't wrap without text
.co-modal-btn-link {
  outline: none;
  padding: 0 20px 0 0;
  white-space: normal;
  word-break: break-all; // Firefox
  word-break: break-word;
  &::after {
    @include font-awesome-free-solid;
    color: $pf-v5-color-black-600;
    content: fa-content($fa-var-pencil-alt);
    line-height: 1;
    margin-left: 5px;
    margin-right: -20px; // width + margin-left
    pointer-events: none;
    position: relative;
    right: 0;
    width: 15px;
  }
  &:hover::after {
    color: $pf-v5-color-black-700;
  }
}

.co-modal-btn-link--inline {
  margin: 0 8px;
}

.co-modal-btn-link--left {
  text-align: left;
}

.co-m-pane__body-group {
  padding: 0 0 30px 0;
}

.co-m-table-grid {
  &__body {
    min-height: 50px;
    position: relative;
    width: 100%;
    .row {
      padding: 10px 20px 10px 0; // right padding creates space for .co-resource-kebab
    }
  }
  &__head {
    font-weight: var(--pf-v5-global--FontWeight--bold);
    padding: 0 20px 10px 0; // right padding to maintain alignment with __body .row
    a {
      cursor: pointer;
    }
  }
  &--bordered {
    .co-m-table-grid__body .row,
    .co-m-table-grid__head {
      border-bottom: var(--pf-v5-global--BorderWidth--sm) solid
        var(--pf-v5-global--BorderColor--100);
    }
  }
  .co-m-table-grid {
    padding-left: 20px;
  }
  .row {
    line-height: normal;
    margin: 0;
    position: relative;
    vertical-align: middle;
  }
}

.co-m-loader {
  min-width: 18px;
}

.co-m-loader-dot__one,
.co-m-loader-dot__two,
.co-m-loader-dot__three {
  animation-delay: 0;
  animation-direction: normal;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: bouncedelay;
  animation-play-state: running;
  animation-timing-function: ease-in-out;
  background: #419eda;
  border-radius: 100%;
  display: inline-block;
  height: 6px;
  width: 6px;
}

.co-m-loader-dot__one {
  animation-delay: -0.32s;
}

.co-m-loader-dot__two {
  animation-delay: -0.16s;
}

@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0.25, 0.25);
  }
  40% {
    transform: scale(1, 1);
  }
}

.no-margin {
  margin: 0 !important;
}

.co-overview-card--gradient {
  position: relative;
  &:after {
    background: linear-gradient(rgba(255, 255, 255, 0), var(--pf-v5-global--BackgroundColor--100));
    bottom: 0;
    content: '';
    left: 0;
    min-height: 2rem;
    pointer-events: none;
    position: absolute;
    width: calc(100% - var(--pf-v5-global--spacer--lg));
  }
}

.co-overview-card__actions {
  align-self: center !important;
}
