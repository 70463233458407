.yaml-editor {
  position: relative;
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
  @media (min-width: $pf-v5-global--breakpoint--md) {
    margin-left: $pf-v5-global-gutter;
    margin-right: $pf-v5-global-gutter;
    padding-top: $pf-v5-global-gutter--md;
  }
  @media (min-width: $pf-v5-global--breakpoint--xl) {
    margin-left: $pf-v5-global-gutter--md;
    margin-right: $pf-v5-global-gutter--md;
  }
}

.yaml-editor__buttons {
  background-color: var(--pf-v5-global--BackgroundColor--100);
  border-top: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  padding: 20px $pf-v5-global-gutter;
  margin-top: auto;
  z-index: 10;
  @media (min-width: $pf-v5-global--breakpoint--md) {
    padding-left: 0;
    padding-right: 0;
  }
}

// only used in packages and will be orphaned when packages move out
.yaml-editor__header {
  border-bottom: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  padding: 20px $pf-v5-global-gutter;
  @media (min-width: $pf-v5-global--breakpoint--xl) {
    padding-left: $pf-v5-global-gutter--md;
    padding-right: $pf-v5-global-gutter--md;
  }
}

// only used in packages and will be orphaned when packages move out
.yaml-editor__header-text {
  margin: 0;
}

.yaml-editor__link {
  display: inline-block;
}

.yaml-editor__links {
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 1;
  @media (max-width: $screen-sm-max) {
    display: none;
  }
}

// This can't be customized in a theme, so use CSS.
.yaml-editor .monaco-editor .margin .current-line {
  background-color: var(--pf-v5-global--BackgroundColor--dark-200);
}
