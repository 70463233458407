.co-cloudshell-exec {
  &__container-error {
    background-color: var(--pf-v5-global--BackgroundColor--100);
    height: 100%;
  }

  &__error-msg {
    color: var(--pf-v5-global--danger-color--100) !important;
  }
}
