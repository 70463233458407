.ocs-radio-group-field {
  padding-left: var(--pf-v5-global--spacer--sm);

  input[type='radio'] {
    margin-top: 0;
  }

  &:not(.ocs-radio-group-field--inline) {
    .pf-v5-c-radio {
      padding-bottom: var(--pf-v5-global--spacer--md);
    }
    .pf-v5-c-radio:last-of-type {
      padding-bottom: 0;
    }
    .ocs-radio-group-field__children {
      padding-top: var(--pf-v5-global--spacer--sm);
    }
  }

  &--inline {
    display: flex;
    align-items: center;

    > .pf-v5-c-form__group-label {
      padding-bottom: 0;
      margin-right: var(--pf-v5-global--spacer--md);
    }
  }
  .pf-v5-c-radio {
    &__description {
      width: 100%;
    }
  }
}
