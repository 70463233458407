.ocs-shortcut {
  &__cell {
    padding-bottom: var(--pf-v5-global--spacer--sm);

    &:first-child {
      padding-right: var(--pf-v5-global--spacer--md);
      text-align: right;
      white-space: nowrap;
      vertical-align: top;
    }
  }

  &__command:not(:last-child):after {
    content: ' + ';
  }
}
