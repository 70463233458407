.cos-status-box {
  padding: 40px 20px;

  &__title {
    color: var(--pf-v5-global--Color--100);
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;

    .cos-error-title {
      color: var(--pf-v5-global--danger-color--100);
    }
  }

  &__detail {
    color: var(--pf-v5-global--Color--200);
    font-size: $font-size-base;
    max-width: 500px;
    margin: 0 auto;
  }

  &__access-denied-icon {
    margin: 10px;
  }

  &__loading-message {
    margin-top: var(--pf-v5-global--spacer--sm);
  }
}

.cos-status-box--loading {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 50px;
  width: 100%;
  flex-direction: column;
}

.co-m-loader--inline {
  display: inline-block;
  margin: 0;
  position: static;
}

.co-m-timeout-error {
  margin-bottom: 30px;
  margin-top: 20px;
  text-align: center;
}

.loading-box {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}
