
@import '~@patternfly/patternfly/components/Label/label.scss';

$kinds: (
  alert: $color-alert-dark,
  alertmanager: $color-alertmanager-dark,
  alertrule: $color-alertrule-dark,
  configmap: $color-configmap-dark,
  daemonset: $color-replicaset-dark,
  deployment: $color-deployment-dark,
  deploymentconfig: $color-deploymentconfig-dark,
  ingress: $color-ingress-dark,
  job: $color-job-dark,
  machine: $color-node-dark,
  machineautoscaler: $color-node-dark,
  machineconfig: $color-node-dark,
  machineconfigpool: $color-node-dark,
  machinedeployment: $color-node-dark,
  machineset: $color-node-dark,
  metricstarget: $color-alert-dark,
  namespace: $color-namespace-dark,
  node: $color-node-dark,
  pod: $color-pod-dark,
  project: $color-namespace-dark,
  replicaset: $color-replicaset-dark,
  replicationcontroller: $color-controller-dark,
  secret: $color-secret-dark,
  service: $color-service-dark,
  serviceaccount: $color-serviceaccount-dark,
  servicemonitor: $color-service-dark,
  silence: $color-alert-dark,
);

$kindsToModifier: (
  alert: 'blue',
  alertmanager: 'orange',
  alertrule: 'purple',
  clusterserviceversion: 'blue',
  configmap: 'purple',
  daemonset: 'blue',
  deployment: 'blue',
  deploymentconfig: 'blue',
  ingress: 'purple',
  job: 'blue',
  machine: 'purple',
  machineautoscaler: 'purple',
  machineconfig: 'purple',
  machineconfigpool: 'purple',
  machinedeployment: 'purple',
  machineset: 'purple',
  metricstarget: 'blue',
  namespace: 'green',
  node: 'purple',
  pod: 'cyan',
  project: 'green',
  replicaset: 'blue',
  replicationcontroller: 'blue',
  secret: 'orange',
  service: 'green',
  serviceaccount: 'purple' ,
  servicemonitor: 'green',
  silence: 'blue',
);

@each $kind, $color in $kinds {
  .co-text-#{$kind}:where(:not([class*='co-m-'])) {
    color: $color;

    &:link, &:visited, &:hover, &:active {
      color: $color;
      text-decoration: none;
    }
  }
}

@each $kind, $color in $kindsToModifier {
  .co-label {
    &.co-m-#{$kind} {
      @extend .pf-m-#{$color};
    }
  }
}
