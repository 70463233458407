.ocs-quick-search-modal {
  --pf-v5-c-modal-box--BackgroundColor: none !important;
  box-shadow: none !important;
}

.ocs-quick-search-modal__no-backdrop .pf-v5-c-backdrop {
  background: none;
  position: absolute;
  overflow: hidden;
}

