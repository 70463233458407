.dps-icons {
  &__green-check-icon {
    fill: var(--pf-v5-global--palette--green-500) !important;
  }
  &__red-exclamation-icon {
    fill: var(--pf-v5-global--danger-color--100) !important;
  }
  &__yellow-exclamation-icon {
    fill: var(--pf-v5-global--warning-color--100) !important;
  }
  &__blue-info-icon {
    fill: var(--pf-v5-global--palette--blue-300) !important;
  }
}
