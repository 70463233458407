.pf-v5-c-menu-toggle.co-user-preference__namespace-menu-toggle {
  padding: var(--pf-v5-global--spacer--form-element) var(--pf-v5-global--spacer--sm) var(--pf-v5-global--spacer--form-element) var(--pf-v5-global--spacer--sm) !important;
  background-color: var(--pf-v5-global--BackgroundColor--400);
}

.pf-v5-c-menu-toggle.co-user-preference__namespace-menu-toggle::before,
.pf-v5-c-menu-toggle.co-user-preference__namespace-menu-toggle::after {
  border-width: var(--pf-v5-global--BorderWidth--sm) !important;
}

.pf-v5-c-menu-toggle.co-user-preference__namespace-menu-toggle .pf-v5-c-menu-toggle__text {
  font-size: var(--pf-v5-global--FontSize--md);
}

.co-user-preference__namespace-menu__last-viewed {
  padding-top: 0 !important;
  margin-bottom: 0 !important;
}

