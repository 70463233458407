.co-actions {
  align-items: flex-end;
  display: flex;
  font-family: var(--pf-v5-global--FontFamily--text);
  @media (max-width: $screen-xs) {
    flex-direction: column;
  }
}

@media (max-width: $screen-xs) {
  .co-action-buttons__btn {
    margin-bottom: 10px;
  }
}

.co-action-buttons__btn, // btn along with drowdown actions
.co-actions-menu {
  margin-left: 10px;
}

.co-m-nav-title {
  margin-top: $pf-v5-global-gutter--md;
  padding: 0 $pf-v5-global-gutter 0;
  @media (min-width: $pf-v5-global--breakpoint--xl) {
    padding-left: $pf-v5-global-gutter--md;
    padding-right: $pf-v5-global-gutter--md;
  }
  &--row {
    @media (min-width: $screen-sm) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &--detail {
    border-bottom: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
    flex-shrink: 0; // do not allow to shrink
  }
  // Positioned after --detail to take precedence, since they will be a siblings
  &--breadcrumbs {
    margin-top: 0;
  }
}
