.build-overview__item {
  display: flex;
  flex-direction: column;
}

.build-overview__item-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.build-overview__item-time {
  display: inline-block;
}

.build-overview__status {
  flex-wrap: wrap;
}
