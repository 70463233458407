.co-user-preference-page {
  display: flex;
  min-height: 100%;
  background-color: var(--pf-v5-global--BackgroundColor--200);
  overflow-y: auto;
  flex-direction: column;
}

.co-user-preference-page .ocs-page-layout__title {
  margin-top: 0;
  padding-top: var(--pf-v5-global--spacer--lg);
}

.co-user-preference-page-content {
  display: flex;
  flex-direction: row;
  background-color: var(--pf-v5-global--BackgroundColor--100);
  min-height: 100%;
}

@media (min-width: 769px) {
  .co-user-preference-page-content__tabs {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .co-user-preference-page-content__tabs {
    min-width: 220px;
  }
}

@media (min-width: 1200px) {
  .co-user-preference-page-content__tabs {
    max-width: 220px;
  }
}

.co-user-preference-page-content__tab-content {
  margin: var(--pf-v5-global--spacer--lg);
  max-width: 100%;
}

.co-user-preference-page-content .pf-v5-c-tabs__scroll-button {
  display: none;
}

