.pf-v5-c-about-modal-box {
  dt {
    color: var(--pf-v5-global--color--100);
    font-size: var(--pf-v5-c-content--FontSize);
    margin-bottom: var(--pf-v5-global--spacer--md);
    text-transform: none;
  }
  dd {
    margin-bottom: var(--pf-v5-global--spacer--md);
  }
  p {
    color: var(--pf-v5-global--color--100);
    margin-bottom: var(--pf-v5-global--spacer--xl);
  }
}

.co-about-modal__alert {
  margin-bottom: var(--pf-v5-global--spacer--xl);
}
