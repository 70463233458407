.co-disabled {
  color: var(--pf-v5-global--disabled-color--100);
  height: 100%;
  overflow: hidden;
  position: relative;
}

.co-disabled::before {
  bottom: 0;
  content: '';
  cursor: not-allowed;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  user-select: none;
  z-index: 10;
}
