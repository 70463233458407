.value-from {
  width: 100%;

  .value-from-pair {
    width: 100%;
    @media (min-width: $screen-md-min) {
      width: 50%;
    }
  }

  .pf-v5-c-dropdown,
  .pf-v5-c-dropdown__toggle {
    width: 100%;
  }

  .pf-v5-c-dropdown__menu {
    min-width: 250px;
    width: 100%;
  }
}
@media (max-width: $screen-xs-max) {
  .value-from__menu {
    left: auto;
    right: 0;
  }
}

.value-from--key {
  padding-top: 5px;
}
