.odc-pod-status-tooltip__content {
  align-items: center;
  display: flex;
}

.odc-pod-status-tooltip__status-box {
  width: var(--pf-v5-global--icon--FontSize--sm);
  height: var(--pf-v5-global--icon--FontSize--sm);
  margin-right: var(--pf-v5-global--spacer--sm);
}

.odc-pod-status-tooltip__status-count {
  margin-right: var(--pf-v5-global--spacer--xs);
}

