:root {
  --pf-v5-global--FontSize--md: 14px;
  --pf-global--FontSize--md: 14px;
  --pf-v5-global--FontSize--sm: 13px;
  --pf-global--FontSize--sm: 13px;
}

.co-catalog-tile {
  height: 100%;
  width: 260px;
}

.co-clusterserviceversion-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7.5px 0;
}

.co-clusterserviceversion-details__field {
  padding-bottom: var(--pf-v5-global--spacer--lg);
}

.co-clusterserviceversion-details__field-description {
  margin-bottom: 0;
}

.co-clusterserviceversion-table-header__icon {
  margin-left: var(--pf-v5-global--spacer--xs);
}

.co-clusterserviceversion-link:focus,
.co-clusterserviceversion-link:hover {
  text-decoration: none;
}

.co-clusterserviceversion-link:focus .co-clusterserviceversion-logo__name__clusterserviceversion,
.co-clusterserviceversion-link:hover .co-clusterserviceversion-logo__name__clusterserviceversion {
  text-decoration: underline;
}

.co-clusterserviceversion-logo {
  align-items: flex-start;
  display: flex;
}

.co-clusterserviceversion-logo__icon {
  padding: var(--pf-v5-global--spacer--xs);
  border-radius: var(--pf-v5-global--BorderRadius--sm);
  background: var(--pf-v5-global--palette--white);
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  margin: 0 15px 5px 0;
}

.co-clusterserviceversion-logo__name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.co-clusterserviceversion-logo__name__clusterserviceversion {
  font-size: 15px !important;
  margin-bottom: 5px !important;
}

.co-m-pane__heading--logo .co-clusterserviceversion-logo__name__provider {
  font-size: var(--pf-v5-global--FontSize--sm);
}

.co-crd-card-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.co-crd-card-row .pf-v5-c-card {
  margin-bottom: 20px;
  margin-right: 20px;
  width: 300px;
}

.co-operand-details__compact-expand {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.co-operand-details__section--info dd {
  margin-bottom: 20px !important;
  min-width: 0;
  overflow-wrap: break-word;
}

.co-catalog-install-modal .modal-header .co-clusterserviceversion-logo__icon {
  border: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
}

.co-catalog-details {
  display: flex;
  flex-direction: column;
}

.co-catalogsource-list__section {
  margin-bottom: 40px;
}

.co-catalogsource-list__section__packages {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.co-clusterserviceversion-row__status {
  display: flex;
  flex-direction: column;
}

.co-spec-descriptor--resource-requirements {
  border: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--300);
  padding: 20px 0 20px 20px;
  margin-top: 5px;
}

.co-spec-descriptor--switch {
  align-items: center;
  display: flex;
  height: 25px;
}

.co-create-operand__header {
  border-bottom: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  padding: 0 1rem var(--pf-v5-global--spacer--lg);
}

@media (min-width: 1200px) {
  .co-create-operand__header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.co-create-operand__header-text {
  margin: var(--pf-v5-global--spacer--lg) 0 0 !important;
}

.co-affinity-term {
  border: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--300);
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding: 10px 0 5px 15px;
}

.co-affinity-term__remove {
  margin-left: auto !important;
}

.co-affinity-term__topology {
  display: flex;
}

.co-affinity-term__topology-input,
.co-affinity-term__weight-input {
  margin-bottom: 15px;
}

.co-affinity-term__topology-input {
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  width: 100%;
}

.co-affinity-term__weight-input {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  width: 100px;
}

.co-operand-field-group {
  border: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--300);
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-left: 15px;
  padding: 10px 15px 5px;
}

.co-operand-field-group-title {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.catalog-source__table-row--disabled {
  background-color: var(--pf-v5-global--palette--black-200);
  color: var(--pf-v5-global--Color--400);
}

.key-operator-value__action {
  padding: 0;
  width: 28px;
}

@media (max-width: 991px) {
  .key-operator-value__action--stacked,
  .key-operator-value__value-field--stacked {
    padding-top: 10px;
  }
}

.key-operator-value__delete-button {
  padding-left: 0 !important;
}

.key-operator-value__heading-button {
  height: 23px;
}

.key-operator-value__name-field,
.key-operator-value__operator-field,
.key-operator-value__value-field {
  padding-right: 0 !important;
}

.key-operator-value__row {
  padding-bottom: 15px;
}

.olm-descriptors__invalid-pod-descriptor {
  display: inline-block;
  margin-bottom: 20px !important;
}

.olm-descriptors__group {
  display: flex;
  justify-content: start;
}

.olm-descriptors__group>div {
  margin-right: var(--pf-v5-global--spacer--sm);
  flex-grow: 1;
}

#operator-install-page {
  margin: 0 1rem;
}

@media (min-width: 768px) {
  #operator-install-page {
    margin: 1.5rem;
    max-width: 600px;
    min-width: 375px;
    width: 100%;
  }
}

#operator-install-page .pf-v5-c-card {
  margin: 10px auto;
}

#operator-install-page .pf-v5-c-alert {
  margin-bottom: 10px;
}

.co-clusterserviceversion-install__heading {
  margin-top: 0;
}

.co-clusterserviceversion__box {
  border: 1px solid var(--pf-v5-global--BorderColor--300);
  margin: var(--pf-v5-global--spacer--md) 0;
  padding: var(--pf-v5-global--spacer--md) var(--pf-v5-global--spacer--md) var(--pf-v5-global--spacer--xs);
}

.co-operator-install-page__indicator {
  text-align: center;
}

.co-operator-install-page__pkg-indicator {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.co-operator-install-page__main {
  background-color: var(--pf-v5-global--BackgroundColor--200);
  height: 100%;
}

.co-operator-install-page__link {
  display: flex;
  align-items: center;
}

.co-operator-uninstall-alert__list {
  list-style: none;
  padding-left: 0;
}

.co-operator-uninstall-alert__list-item {
  padding-bottom: var(--pf-v5-global--spacer--sm);
}

@media (min-width: 768px)and (min-height: 600px) {
  .co-operator-uninstall__operands-section {
    display: block;
    max-height: calc(100vh - 400px);
    overflow-y: auto;
  }
}

.co-operator-uninstall__operands-section loading-skeleton--table::after {
  min-height: 160px;
}

.co-operator-details__actions {
  align-items: baseline;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .co-operator-details__actions {
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
}

.co-operator-details__toggle-value {
  flex-grow: 1;
  margin-right: var(--pf-v5-global--spacer--xl);
}

.co-operator-details__toggle-value .radio-inline input[type=radio] {
  position: relative;
}

.co-operator-details__toggle-value label {
  font-weight: var(--pf-v5-global--FontWeight--normal);
}

