.odc-selector-card {
  position: relative;
  width: 136px;
  min-height: 118px;
  margin: 4px !important;
  align-items: center;
  border: 0;
}

.odc-selector-card__icon {
  height: 36px;
  max-width: 100%;
  padding: var(--pf-v5-global--spacer--xs);
  border-radius: var(--pf-v5-global--BorderRadius--sm);
  background: var(--pf-v5-global--palette--white);
}

.odc-selector-card__title {
  font-size: var(--pf-v5-global--FontSize--sm);
  font-weight: var(--pf-v5-global--FontWeight--bold);
  line-height: var(--pf-v5-global--LineHeight--sm);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.odc-selector-card__recommended {
  position: absolute;
  color: var(--pf-v5-global--primary-color--100);
  font-size: var(--pf-v5-global--FontSize--md);
  top: 3px;
  right: 6px;
}

.odc-selector-card.is-selected,
.odc-selector-card:hover,
.odc-selector-card:focus,
.odc-selector-card:active {
  outline: var(--pf-v5-global--active-color--100) 3px solid;
  outline-offset: -3px;
}

.odc-selector-card .pf-v5-c-card__body {
  padding: 5px !important;
}

