.co-field-level-help {
  margin-left: 5px;

  &:hover .co-field-level-help__icon {
    color: var(--pf-v5-global--Color--dark-100);
  }

  .co-field-level-help__icon {
    color: var(--pf-v5-global--Color--dark-200);
    font-size: 12px;
  }
}
