.ocs-quick-search-bar.pf-v5-c-input-group {
  background-color: var(--pf-v5-global--BackgroundColor--400);
}

.ocs-quick-search-bar.pf-v5-c-input-group__item.pf-m-box {
  border: none;
}

.ocs-quick-search-bar:hover {
  cursor: move;
}

.ocs-quick-search-bar__input-dummy {
  padding: 5px 8px;
  position: fixed;
  visibility: hidden;
  max-width: 100%;
  z-index: 0;
  white-space: nowrap;
}

.ocs-quick-search-bar__input {
  cursor: auto;
  border: none !important;
  display: block;
  z-index: 1;
}

.ocs-quick-search-bar__input>input {
  height: 60px !important;
  outline: none;
  border: none !important;
}

.ocs-quick-search-bar__input-wrapper {
  max-width: 100%;
  display: inline-flex;
}

.ocs-quick-search-bar__input.pf-v5-c-form-control:before,
.ocs-quick-search-bar__input.pf-v5-c-form-control:after {
  display: none;
}

.ocs-quick-search-bar__no-results {
  white-space: nowrap;
}

.ocs-quick-search-bar__border-none {
  border: none !important;
}

.ocs-quick-search-bar__spinner {
  margin-left: auto !important;
}

