.odc-multi-column-field__row {
  position: relative;
  margin-right: var(--pf-v5-global--spacer--lg);
  margin-bottom: var(--pf-v5-global--spacer--md);
}

.odc-multi-column-field__header {
  position: relative;
  margin-right: var(--pf-v5-global--spacer--lg);
  margin-bottom: var(--pf-v5-global--spacer--sm);
  max-height: var(--pf-v5-global--spacer--xl);
}

.odc-multi-column-field__col {
  margin-right: var(--pf-v5-global--spacer--md);
}

.odc-multi-column-field__col--button {
  line-height: var(--pf-v5-global--spacer--xl);
  position: absolute;
  right: -30px;
  top: 0;
}

.odc-multi-column-field__empty-message {
  margin-bottom: var(--pf-v5-global--spacer--sm);
}

.odc-multi-column-field__header--required-label {
  margin-left: var(--pf-v5-global--spacer--xs);
  font-size: var(--pf-v5-global--FontSize--sm);
  color: var(--pf-v5-global--danger-color--100);
}

