:root {
  --pf-v5-global--FontSize--md: 14px;
  --pf-global--FontSize--md: 14px;
  --pf-v5-global--FontSize--sm: 13px;
  --pf-global--FontSize--sm: 13px;
}

.co-namespace-dropdown {
  margin-right: 20px;
  max-width: 60%;
}

.pf-v5-c-menu.co-namespace-dropdown__menu {
  position: absolute;
  --pf-v5-c-menu--MinWidth: 210px;
}

@media (min-width: 768px) {
  .pf-v5-c-menu.co-namespace-dropdown__menu {
    --pf-v5-c-menu--MinWidth: 325px;
  }
}

.pf-v5-c-menu-toggle.co-namespace-dropdown__menu-toggle {
  background-color: var(--co-namespace-dropdown__menu-toggle--BackgroundColor, inherit);
  font-size: 15px !important;
  padding: 2px 0 !important;
  width: 100%;
}

@media (min-width: 768px) {
  .pf-v5-c-menu-toggle.co-namespace-dropdown__menu-toggle {
    font-size: 16px !important;
    padding-bottom: 9px !important;
    padding-top: 9px !important;
  }
}

.pf-v5-c-menu-toggle.co-namespace-dropdown__menu-toggle::before,
.pf-v5-c-menu-toggle.co-namespace-dropdown__menu-toggle::after {
  border-width: 0 !important;
}

.pf-v5-c-menu-toggle.co-namespace-dropdown__menu-toggle:not(:disabled) {
  color: inherit;
}

.pf-v5-c-menu-toggle.co-namespace-dropdown__menu-toggle:disabled {
  background: none;
  color: var(--pf-v5-global--disabled-color--200);
}

.co-namespace-dropdown__switch .pf-v5-c-switch__label {
  padding-right: 60px;
}

.co-namespace-dropdown__switch .pf-v5-c-switch__toggle {
  position: absolute;
  right: 0;
}

