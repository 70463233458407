.ocs-popup-kebab-menu {
  position: fixed;
  min-width: 150px;
  max-width: 200px;

  &__backdrop {
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__container {
    z-index: calc(var(--pf-v5-c-page__header--ZIndex) + 10);
  }

  // input component used to trap focus within the context menu
  &__faux-input {
    border: none;
    display: block;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
  }
}
