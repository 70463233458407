.co-add-remove-form__entry {
  position: relative;
}

.co-add-remove-form__entry + .co-add-remove-form__entry {
  border-top: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  padding-top: 10px;
}

.co-add-remove-form__link--remove-entry {
  display: flex;
  flex-flow: row-reverse;
}

.co-required > * {
  display: inline-block;
}

.co-required:after {
  color: var(--pf-v5-global--danger-color--100);
  content: '*';
  font-size: $font-size-base;
  padding-left: 3px;
  vertical-align: top;
}

.co-form-section__label {
  font-size: 14px;
  margin-bottom: $pf-v5-global-gutter;
}

.co-form-section__separator {
  border-top: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--300);
  margin: 30px 0;
}

.co-form-subsection {
  margin-left: 20px;
}

.co-kbd {
  border: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  border-radius: 3px;
  color: var(--pf-v5-global--Color--200);
  font-size: var(--pf-v5-global--FontSize--sm);
  padding: 1px 3px;
}

.co-kbd__filter-input {
  border-color: var(--pf-v5-global--BorderColor--300);
}

.co-label--plain {
  font-weight: var(--pf-v5-global--FontWeight--normal);
  margin-bottom: 0px;
  display: inline;
}

// Restrict form widths
.co-m-pane__form {
  @media (min-width: 769px) {
    max-width: 650px;
  }

  @media (min-width: 992px) {
    min-width: 650px;
  }

  @media (min-width: 1200px) {
    max-width: 50%;
  }
}

.co-text-filter:focus + .co-text-filter-feedback {
  display: none;
}

.co-text-filter-feedback {
  display: block;
  height: 29px;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-align: center;
  top: 5px;
  width: 29px;
  z-index: 2;
}

.has-feedback .pf-v5-c-form-control {
  --pf-v5-c-form-control--PaddingRight: 25px;
}

.pf-v5-c-form__actions--right {
  justify-content: flex-end;
}

.pf-v5-c-form__checkbox-row {
  display: grid;
  row-gap: $pf-v5-global-gutter;
}

.pf-v5-c-form__group--no-top-margin {
  margin-top: 0 !important;
}
