.co-m-requirement {
  &__icon {
    margin-right: 4px;
  }

  &__label {
    min-width: 0; // required so co-break-word on .co-m-requirement__link works
  }

  &__link  {
    align-items: baseline;
    display: flex;
    @include co-break-word;
  }
}
