@import '~@patternfly/patternfly/components/ClipboardCopy/clipboard-copy.css';

@import '~@patternfly/patternfly/components/CodeBlock/code-block.css';

.ocs-code-block__pre {
  display: flex;
  border: none;
  border-radius: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
}

.ocs-markdown-execute-snippet__button>i.fa-check {
  display: none;
}

.ocs-markdown-execute-snippet__button>i.fa-play {
  display: inline;
}

.ocs-markdown-execute-snippet__button[data-executed]>i.fa-check {
  display: inline;
}

.ocs-markdown-execute-snippet__button[data-executed]>i.fa-play {
  display: none;
}

