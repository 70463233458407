.ocs-getting-started-grid {
  --min-column-width: 220px;
}

.ocs-getting-started-grid__header.pf-v5-c-card__header {
  padding-right: var(--pf-v5-global--spacer--sm);
}

.ocs-getting-started-grid__tooltip {
  white-space: pre-line;
}

.ocs-getting-started-grid__tooltip-icon {
  padding-left: var(--pf-v5-global--spacer--sm);
}

.ocs-getting-started-grid__action-dropdown .pf-v5-c-dropdown__menu {
  width: 280px;
}

.ocs-getting-started-grid__action-dropdown .pf-v5-c-dropdown__menu-item-description {
  white-space: pre-wrap;
}

.ocs-getting-started-grid__content.pf-v5-c-card__body {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--min-column-width), 1fr));
  padding: 0 0 calc(var(--pf-v5-c-card--child--PaddingBottom)/2) 0;
  overflow: hidden;
}

.ocs-getting-started-grid__content.pf-v5-c-card__body>.pf-v5-l-flex.pf-m-grow.pf-m-column {
  border-right: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  margin-right: calc(-1*var(--pf-v5-global--BorderWidth--sm));
  padding-top: calc(var(--pf-v5-c-card--first-child--PaddingTop)/2);
  padding-bottom: calc(var(--pf-v5-c-card--child--PaddingBottom)/2);
  padding-left: var(--pf-v5-c-card--child--PaddingLeft);
  padding-right: var(--pf-v5-c-card--child--PaddingRight);
}

