.co-cloud-shell-tab {
  &__body {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  &__header {
    background-color: var(--pf-v5-global--BackgroundColor--200);
    align-items: center;
    display: flex;
    flex-shrink: 0;
    min-height: var(--pf-v5-global--target-size--MinHeight);
    &-text {
      padding: 0 var(--pf-v5-global--spacer--md);
    }
  }
}
