.ocs-popup-kebab-menu {
  position: fixed;
  min-width: 150px;
  max-width: 200px;
}

.ocs-popup-kebab-menu__backdrop {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ocs-popup-kebab-menu__container {
  z-index: calc(var(--pf-v5-c-page__header--ZIndex) + 10);
}

.ocs-popup-kebab-menu__faux-input {
  border: none;
  display: block;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
}

