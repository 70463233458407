.odc-catalog-details-modal {
  &__header {
    align-items: baseline;
  }

  &__sbo_alert {
    margin-left: var(--pf-v5-global--spacer--lg);
    margin-right: var(--pf-v5-global--spacer--lg);
    margin-bottom: var(--pf-v5-global--spacer--md);
  }
}
