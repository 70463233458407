.co-list-input__help-block {
  margin-bottom: 10px
}

.co-list-input__row {
  display: flex;
  margin-bottom: 5px;
}

.co-list-input__value {
  flex-grow: 1;
}
