.ocs-quick-search-bar {
  &.pf-v5-c-input-group {
    background-color: var(--pf-v5-global--BackgroundColor--400);
    &__item.pf-m-box {
      border: none;
    }
  }
  &:hover {
    cursor: move;
  }
  &__input-dummy {
    padding: 5px 8px;
    position: fixed;
    visibility: hidden;
    max-width: 100%;
    z-index: 0;
    white-space: nowrap;
  }
  &__input {
    cursor: auto;
    border: none !important;
    display: block;
    z-index: 1;
    > input {
      height: 60px !important;
      outline: none;
      border: none !important;
    }
    &-wrapper {
      max-width: 100%;
      display: inline-flex;
    }
    &.pf-v5-c-form-control:before, &.pf-v5-c-form-control:after {
      display: none;
    }
  }
  &__no-results {
    white-space: nowrap;
  }
  &__border-none {
    border: none !important;
  }
  &__spinner {
    margin-left: auto !important;
  }
}
