// Residual css rules within codebase that pertain to Bootstrap
.form-group {
  margin-bottom: $form-group-margin-bottom;
}

.has-feedback {
  position: relative;
}

.help-block {
  display: block; // account for any element using help-block
  margin-top: 5px;
  margin-bottom: 10px;
  color: var(
    --pf-v5-global--Color--100
  ); // matches --pf-v5-c-helper-text__item-text--Color and picks up dark theme variable
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

//
// List groups
// --------------------------------------------------

// Base class
//
// Easily usable on <ul>, <ol>, or <div>.

.list-group {
  // No need to set list-style: none; since .list-group-item is block level
  margin-bottom: 20px;
  padding-left: 0; // reset padding because ul and ol
}

// Individual list items
//
// Use on `li`s or `div`s within the `.list-group` parent.

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  // Place the border on the list items and negative margin up for better styling
  margin-bottom: -1px;
  background-color: var(--pf-v5-global--BackgroundColor--100);
  border: 1px solid var(--pf-v5-global--BorderColor--100);
}

.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;

  // These are used on elements with <label> descendants
  &.disabled,
  fieldset[disabled] & {
    label {
      cursor: not-allowed;
    }
  }

  label {
    min-height: $line-height-computed; // Ensure the input doesn't jump when there is no text
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer;
  }
}
.radio input[type='radio'],
.radio-inline input[type='radio'],
.checkbox input[type='checkbox'],
.checkbox-inline input[type='checkbox'] {
  position: absolute;
  margin-top: 4px \9;
  margin-left: -20px;
}

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px; // Move up sibling radios or checkboxes for tighter spacing
}

// Radios and checkboxes on same line
.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  margin-right: 20px;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;

  // These are used directly on <label>s
  &.disabled,
  fieldset[disabled] & {
    cursor: not-allowed;
  }
}

.small,
small {
  font-size: var(--pf-v5-global--FontSize--sm) !important;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: $line-height-computed;
  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: var(
          --pf-v5-global--spacer--sm
        ); // matches --pf-v5-c-table--m-compact--cell--Padding
        vertical-align: top;
        border-top: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--300);
      }
    }
  }
  // Bottom align for column headings
  > thead > tr > th {
    vertical-align: bottom;
    border-bottom: var(--pf-v5-global--BorderWidth--md) solid var(--pf-v5-global--BorderColor--300);
  }
  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }
}

.text-muted {
  color: var(--pf-v5-global--Color--200);
}

.text-secondary {
  color: var(--pf-v5-global--Color--200);
}
