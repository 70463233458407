.co-deploy-image {
  &__name {
    margin-top: 15px;
  }

  &__namespace .pf-v5-c-dropdown {
    width: 100%;
  }
}

.co-image-name-results {
  border-bottom: 1px solid var(--pf-v5-global--BorderColor--300);
  border-top: 1px solid var(--pf-v5-global--BorderColor--300);
  margin: 30px 0;
  padding: 30px 0;

  &__heading {
    .co-timestamp {
      display: inline-flex;
    }

    small {
      margin-left: 7px;
    }
  }

  &__loading {
    text-align: center;
  }

  &__loading-error {
    margin-bottom: 0;
    margin-top: 10px;
  }

  &__loading-heading {
    margin: 0;
  }
}
