// This is a workaround because the Chrome browser automatically sets a
// background color and remove the background image when the input field
// is autofilled.
// https://bugzilla.redhat.com/show_bug.cgi?id=2039477
.oc-inputfield {
  // Required by the position: absolute below.
  position: relative;

  // Disable the standard icon
  .pf-v5-c-form-control.pf-m-success,
  .pf-v5-c-form-control.pf-m-warning,
  .pf-v5-c-form-control[aria-invalid='true'] {
    background-image: none;
  }

  // Add an overlay icon that also work with browser autofill 'background colors'
  &__validation-icon.pf-v5-c-form-control {
    // Align the icon on the right side
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    // We need pf-v5-c-form-control to get the form control variables below.
    // But we need to unset the form-control background and border for the icon.
    background-color: transparent;
    border: 0 transparent;

    &.success {
      width: var(--pf-v5-c-form-control--success--PaddingRight);
      background-image: var(--pf-v5-c-form-control--success--BackgroundUrl);
      background-position: var(--pf-v5-c-form-control--success--BackgroundPosition);
      background-size: var(--pf-v5-c-form-control--success--BackgroundSize);
      border-bottom: var(--pf-v5-c-form-control--success--BorderBottomWidth) transparent;
      padding-bottom: var(--pf-v5-c-form-control--success--PaddingBottom);
    }
    &.warning {
      width: var(--pf-v5-c-form-control--warning--PaddingRight);
      background-image: var(--pf-v5-c-form-control--warning--BackgroundUrl);
      background-position: var(--pf-v5-c-form-control--warning--BackgroundPosition);
      background-size: var(--pf-v5-c-form-control--warning--BackgroundSize);
      border-bottom: var(--pf-v5-c-form-control--warning--BorderBottomWidth) transparent;
      padding-bottom: var(--pf-v5-c-form-control--warning--PaddingBottom);
    }
    &.error {
      width: var(--pf-v5-c-form-control--invalid--PaddingRight);
      background-image: var(--pf-v5-c-form-control--invalid--BackgroundUrl);
      background-position: var(--pf-v5-c-form-control--invalid--BackgroundPosition);
      background-size: var(--pf-v5-c-form-control--invalid--BackgroundSize);
      border-bottom-width: var(--pf-v5-c-form-control--invalid--BorderBottomWidth) transparent;
      padding-bottom: var(--pf-v5-c-form-control--invalid--PaddingBottom);
    }
  }
}
