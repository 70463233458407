.oc-inputfield {
  position: relative;
}

.oc-inputfield .pf-v5-c-form-control.pf-m-success,
.oc-inputfield .pf-v5-c-form-control.pf-m-warning,
.oc-inputfield .pf-v5-c-form-control[aria-invalid=true] {
  background-image: none;
}

.oc-inputfield__validation-icon.pf-v5-c-form-control {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 0 transparent;
}

.oc-inputfield__validation-icon.pf-v5-c-form-control.success {
  width: var(--pf-v5-c-form-control--success--PaddingRight);
  background-image: var(--pf-v5-c-form-control--success--BackgroundUrl);
  background-position: var(--pf-v5-c-form-control--success--BackgroundPosition);
  background-size: var(--pf-v5-c-form-control--success--BackgroundSize);
  border-bottom: var(--pf-v5-c-form-control--success--BorderBottomWidth) transparent;
  padding-bottom: var(--pf-v5-c-form-control--success--PaddingBottom);
}

.oc-inputfield__validation-icon.pf-v5-c-form-control.warning {
  width: var(--pf-v5-c-form-control--warning--PaddingRight);
  background-image: var(--pf-v5-c-form-control--warning--BackgroundUrl);
  background-position: var(--pf-v5-c-form-control--warning--BackgroundPosition);
  background-size: var(--pf-v5-c-form-control--warning--BackgroundSize);
  border-bottom: var(--pf-v5-c-form-control--warning--BorderBottomWidth) transparent;
  padding-bottom: var(--pf-v5-c-form-control--warning--PaddingBottom);
}

.oc-inputfield__validation-icon.pf-v5-c-form-control.error {
  width: var(--pf-v5-c-form-control--invalid--PaddingRight);
  background-image: var(--pf-v5-c-form-control--invalid--BackgroundUrl);
  background-position: var(--pf-v5-c-form-control--invalid--BackgroundPosition);
  background-size: var(--pf-v5-c-form-control--invalid--BackgroundSize);
  border-bottom-width: var(--pf-v5-c-form-control--invalid--BorderBottomWidth) transparent;
  padding-bottom: var(--pf-v5-c-form-control--invalid--PaddingBottom);
}

