.odc-catalog-details-modal__header {
  align-items: baseline;
}

.odc-catalog-details-modal__sbo_alert {
  margin-left: var(--pf-v5-global--spacer--lg);
  margin-right: var(--pf-v5-global--spacer--lg);
  margin-bottom: var(--pf-v5-global--spacer--md);
}

