.co-search__accordion {
  // Margin between each resource section results
  margin-bottom: var(--pf-v5-global--spacer--lg);
  // Align with search results
  @media (min-width: $screen-sm-min) {
    .co-search__accordion-toggle {
      padding-left: $pf-v5-global-gutter--md;
      padding-right: $pf-v5-global-gutter--md;
    }
  }
  .co-m-pane__filter-bar {
    // Reduce the margin between the accordion toggle and the create button
    // so that the space is equal above and below the button.
    margin-top: $pf-v5-global-gutter;
  }
  // There's no way to set a class name on this element with the PF4 component,
  // so use a nested selector.
  h3 {
    line-height: normal; // fix clipped descenders
    margin-bottom: 0;
  }
  // There's no way to set a class name on this element, so use the PF4 class.
  .pf-v5-c-accordion__expanded-content-body {
    // Left align the content with the heading on the search page.
    --pf-v5-c-accordion__expanded-content-body--PaddingRight: 0 !important;
    --pf-v5-c-accordion__expanded-content-body--PaddingLeft: 0 !important;
  }

  .pf-v5-c-accordion__expanded-content-body,
  .pf-v5-c-accordion__toggle {
    &:before {
      // The expanded state indicator blends into the left nav. Hide it.
      display: none;
    }
  }
}

.co-search__filter-input {
  width: 100%;
}
