.co-create-secret-warning {
  color: $pf-v5-color-orange-300;
  margin-bottom: 10px;
}

.co-create-secret__form-entry-wrapper {
  position: relative;
}

.co-create-secret-form__link--remove-entry {
  display: flex;
  flex-flow: row-reverse;
}

.co-create-image-secret__form {
  margin-bottom: 25px;
}

.co-create-secret__form-entry-wrapper + .co-create-secret__form-entry-wrapper {
  border-top: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  padding-top: 10px;
}

.co-create-generic-secret__form .co-file-dropzone__textarea {
  min-height: 80px;
}
