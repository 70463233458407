@import '~@patternfly/patternfly/sass-utilities/colors';
@import '../../../styles/skeleton-screen';

.co-inventory-card__item {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.co-inventory-card__item:last-child {
  padding-bottom: 0rem;
}

.co-inventory-card__item-status {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.co-inventory-card__item-title {
  display: flex;
  margin-right: 0.5rem;
}

.co-inventory-card__status {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  margin-left: 1.8rem;
}

.co-inventory-card__status-icon--question {
  color: $pf-v5-color-black-300;
}

.co-inventory-card__status-icon--progress {
  color: $pf-v5-color-black-600;
}

.co-inventory-card__accordion.pf-v5-c-accordion {
  border-bottom: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  box-shadow: none;
  padding: 0;

  h5 {
    font-size: var(--pf-v5-c-card__body--FontSize);
    font-weight: var(--pf-v5-global--FontWeight--normal);
    margin: 0;
  }

  .co-inventory-card__accordion-toggle.pf-v5-c-accordion__toggle {
    --pf-v5-c-accordion__toggle--PaddingTop: 0;
    --pf-v5-c-accordion__toggle--PaddingRight: var(--pf-v5-c-card--child--PaddingRight);
    --pf-v5-c-accordion__toggle--PaddingBottom: 0;
    --pf-v5-c-accordion__toggle--PaddingLeft: 0;

    border-left: none;

    .pf-v5-c-accordion__toggle-text {
      width: 100%;
    }
  }

  .co-inventory-card__accordion-body.pf-v5-c-accordion__expanded-content {
    --pf-v5-c-accordion__expanded-content-body--PaddingTop: 1rem;
    --pf-v5-c-accordion__expanded-content-body--PaddingRight: var(
      --pf-v5-c-card--child--PaddingRight
    );
    --pf-v5-c-accordion__expanded-content-body--PaddingBottom: 1rem;
    --pf-v5-c-accordion__expanded-content-body--PaddingLeft: var(
      --pf-v5-c-card--child--PaddingRight
    );
    --pf-v5-c-accordion__expanded-content-body--expanded-content-body--PaddingTop: 1rem;

    border-left: none;
  }
}

.skeleton-inventory {
  animation: $skeleton-animation;
  background: $skeleton-color;
  height: 20px;
  width: 20px;
  opacity: 0;
  margin-right: 0.5rem;
  &::after {
    background-repeat: no-repeat;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
  }
}
