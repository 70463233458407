@import '~@patternfly/patternfly/sass-utilities/all';
@import '~@console/internal/style/vars';

.oc-nav-header {
  border-bottom: 1px solid var(--pf-v5-global--BackgroundColor--dark-200);
  padding: 0.6rem var(--pf-v5-global--spacer--sm);
  margin-top: var(--pf-v5-global--spacer--sm);

  :where(.pf-v5-theme-dark) & {
    // Match --pf-v5-c-nav__item--before--BorderColor
    border-bottom-color: var(--pf-v5-global--BorderColor--100);
  }

  @media screen and (min-width: $pf-v5-global--breakpoint--xl) {
    padding-left: var(--pf-v5-global--spacer--md);
    padding-right: var(--pf-v5-global--spacer--md);
  }

  &__icon {
    margin-right: var(--pf-v5-global--spacer--sm);
  }

  &__dropdown-toggle--is-empty {
    cursor: default !important;
  }
  .pf-v5-c-dropdown {
    --pf-v5-c-dropdown__menu-item--PaddingLeft: 7px;
    width: 100%;
    cursor: pointer;

    &.pf-m-expanded {
      .pf-v5-c-dropdown__toggle {
        background-color: var(--pf-v5-global--BackgroundColor--dark-200);
        :where(.pf-v5-theme-dark) & {
          background-color: var(--pf-v5-global--BackgroundColor--dark-300);
        }
      }
    }
  }

  .pf-v5-c-dropdown__toggle {
    :where(.pf-v5-theme-dark) & {
      background-color: var(--pf-v5-c-page__sidebar--BackgroundColor);
    }
  }

  .pf-v5-c-dropdown__menu-item,
  // For Edge
  .pf-v5-c-dropdown__toggle {
    h1 {
      font-size: $co-side-nav-font-size;
    }
  }

  .pf-v5-c-dropdown__toggle {
    font-size: $co-side-nav-font-size;
    justify-content: space-between;
    width: 100%;

    .pf-c-dropdown__toggle-icon {
      color: var(--pf-v5-global--Color--light-100);
      font-size: $co-side-nav-section-font-size;
    }

    .pf-v5-c-title {
      color: var(--pf-v5-global--Color--light-100);
      &.pf-m-md {
        font-size: $co-side-nav-section-font-size;
        font-family: var(
          --pf-v5-global--FontFamily--text
        ); // Use RedHatText to match side nav links because buttons by default use RedHatDisplay
      }
    }

    // Needed until https://github.com/patternfly/patternfly-design/issues/1068 is resolved
    &:before {
      border: none;
    }
  }
}
