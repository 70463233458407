.dropdown--full-width {
  &,
  .pf-v5-c-dropdown__toggle {
    justify-content: space-between;
    width: 100%;
  }
}

.dropdown-button--icon-only {
  height: 34.5px !important; // default form element height
  margin-left: -1px;
  min-width: auto !important;
  .pf-v5-c-dropdown {
    --pf-c-dropdown__toggle-icon--MarginRight: 0 !important;
    --pf-c-dropdown__toggle-icon--MarginLeft: 0 !important;
  }
}

.dropdown__disabled {
  color: var(--pf-v5-global--disabled-color--100);
  cursor: not-allowed;
}

.dropdown-menu__divider {
  background-color: var(--pf-v5-global--BackgroundColor--200);
  height: 1px;
  flex-grow: 1;
  margin: 9px 0;
  overflow: hidden;
}

.dropdown-menu__header {
  color: var(--pf-global--Color--100);
  flex-grow: 1;
  font-size: ($font-size-base - 1);
  padding: 3px 10px;
  text-transform: uppercase;
}

.dropdown-menu--text-wrap {
  max-width: 100%;
  li {
    a,
    button {
      @include co-break-word;
      white-space: normal;
    }
  }
}

.favorite {
  color: gold;
}

.co-namespace-bar {
  border-bottom: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  padding: 0 $pf-v5-global-gutter;
  white-space: nowrap;

  @media (min-width: $pf-v5-global--breakpoint--xl) {
    padding-left: $pf-v5-global-gutter--md;
    padding-right: $pf-v5-global-gutter--md;
  }

  &__items {
    align-items: center;
    display: flex;

    .pf-m-plain {
      color: inherit;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}

.co-namespace-bar--no-project {
  padding-left: var(--pf-v5-global--spacer--sm);
  @media screen and (max-width: $pf-v5-global--breakpoint--xl) {
    margin-left: -0.5rem;
  }
}

.co-namespace-selector {
  margin-right: 20px;
  max-width: 60%;

  .pf-v5-c-dropdown__toggle.pf-m-plain {
    font-size: ($font-size-base + 1);
    --pf-v5-c-dropdown__toggle--PaddingBottom: 7px;
    --pf-v5-c-dropdown__toggle--PaddingLeft: 0;
    --pf-v5-c-dropdown__toggle--PaddingRight: 0;

    @media (min-width: $pf-v5-global--breakpoint--md) {
      font-size: ($font-size-base + 2);
      --pf-v5-c-dropdown__toggle--PaddingBottom: 9px;
    }

    &:not(:disabled) {
      color: inherit;
    }

    &__titlePrefix {
      margin-right: 6px;
    }
  }
}

.pf-v5-c-dropdown .co-m-resource-icon {
  margin-left: 2px;
}

.pf-v5-c-dropdown__content-wrap {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  min-width: 0;
}

.pf-v5-c-dropdown__menu {
  list-style: none;
  -webkit-overflow-scrolling: touch;
  @media (min-width: $pf-v5-global--breakpoint--md) {
    &.pf-m-align-right-on-md {
      right: 0;
    }
  }
}

.pf-v5-c-dropdown__toggle {
  max-width: 100%;
}

.pf-v5-c-dropdown__toggle .pf-c-dropdown__toggle-icon {
  flex: 0 0 auto; // so arrow doesn't shrink
}

.pf-v5-c-dropdown__toggle-text {
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  .co-resource-item__resource-name {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .co-resource-item__resource-namespace {
    display: none !important;
  }
}
