.ocs-shortcut__cell {
  padding-bottom: var(--pf-v5-global--spacer--sm);
}

.ocs-shortcut__cell:first-child {
  padding-right: var(--pf-v5-global--spacer--md);
  text-align: right;
  white-space: nowrap;
  vertical-align: top;
}

.ocs-shortcut__command:not(:last-child):after {
  content: " + ";
}

