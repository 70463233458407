.ocs-quick-search-list {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ocs-quick-search-list__list {
  border-top: var(--pf-v5-c-data-list--sm--BorderTopWidth) solid var(--pf-v5-c-data-list--sm--BorderTopColor) !important;
  border-bottom: var(--pf-v5-c-data-list--sm--BorderTopWidth) solid var(--pf-v5-c-data-list--sm--BorderTopColor) !important;
  flex: 1;
  overflow-y: auto;
  margin-bottom: 0 !important;
}

.ocs-quick-search-list__item {
  border-bottom: var(--pf-v5-c-data-list__item--sm--BorderBottomWidth) solid var(--pf-v5-c-data-list__item--sm--BorderBottomColor) !important;
}

.ocs-quick-search-content__list--overflow .ocs-quick-search-list__item:last-of-type {
  border-bottom: 0 !important;
}

.ocs-quick-search-list__item--highlight {
  background-color: var(--pf-v5-global--BackgroundColor--200) !important;
}

.ocs-quick-search-list__item:hover {
  background-color: var(--pf-v5-global--BackgroundColor--200);
}

.ocs-quick-search-list__item-row {
  padding: 0 var(--pf-v5-global--spacer--sm) !important;
}

.ocs-quick-search-list__item-content {
  align-items: center;
}

.ocs-quick-search-list__item-icon {
  padding: var(--pf-v5-global--spacer--xs);
  border-radius: var(--pf-v5-global--BorderRadius--sm);
  background: var(--pf-v5-global--palette--white);
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;
}

.ocs-quick-search-list__item-name {
  font-size: var(--pf-v5-global--FontSize--md);
  font-weight: 400;
  font-family: var(--pf-v5-global--FontFamily--heading);
}

.ocs-quick-search-list__all-items {
  border-bottom: var(--pf-v5-c-data-list__item--sm--BorderBottomWidth) solid var(--pf-v5-c-data-list__item--sm--BorderBottomColor) !important;
}

.ocs-quick-search-list__all-items-link {
  padding: var(--pf-v5-global--spacer--sm) 0 var(--pf-v5-global--spacer--md);
  text-align: center;
  display: flex;
  flex-direction: column;
}

.ocs-quick-search-list__secondary-label {
  margin-left: var(--pf-v5-global--BorderWidth--md);
}

