:root {
  --pf-v5-global--FontSize--md: 14px;
  --pf-global--FontSize--md: 14px;
  --pf-v5-global--FontSize--sm: 13px;
  --pf-global--FontSize--sm: 13px;
}

.oc-nav-header {
  border-bottom: 1px solid var(--pf-v5-global--BackgroundColor--dark-200);
  padding: .6rem var(--pf-v5-global--spacer--sm);
  margin-top: var(--pf-v5-global--spacer--sm);
}

:where(.pf-v5-theme-dark) .oc-nav-header {
  border-bottom-color: var(--pf-v5-global--BorderColor--100);
}

@media screen and (min-width: 1200px) {
  .oc-nav-header {
    padding-left: var(--pf-v5-global--spacer--md);
    padding-right: var(--pf-v5-global--spacer--md);
  }
}

.oc-nav-header__icon {
  margin-right: var(--pf-v5-global--spacer--sm);
}

.oc-nav-header__dropdown-toggle--is-empty {
  cursor: default !important;
}

.oc-nav-header .pf-v5-c-dropdown {
  --pf-v5-c-dropdown__menu-item--PaddingLeft: 7px;
  width: 100%;
  cursor: pointer;
}

.oc-nav-header .pf-v5-c-dropdown.pf-m-expanded .pf-v5-c-dropdown__toggle {
  background-color: var(--pf-v5-global--BackgroundColor--dark-200);
}

:where(.pf-v5-theme-dark) .oc-nav-header .pf-v5-c-dropdown.pf-m-expanded .pf-v5-c-dropdown__toggle {
  background-color: var(--pf-v5-global--BackgroundColor--dark-300);
}

:where(.pf-v5-theme-dark) .oc-nav-header .pf-v5-c-dropdown__toggle {
  background-color: var(--pf-v5-c-page__sidebar--BackgroundColor);
}

.oc-nav-header .pf-v5-c-dropdown__menu-item h1,
.oc-nav-header .pf-v5-c-dropdown__toggle h1 {
  font-size: 14px;
}

.oc-nav-header .pf-v5-c-dropdown__toggle {
  font-size: 14px;
  justify-content: space-between;
  width: 100%;
}

.oc-nav-header .pf-v5-c-dropdown__toggle .pf-c-dropdown__toggle-icon {
  color: var(--pf-v5-global--Color--light-100);
  font-size: 16px;
}

.oc-nav-header .pf-v5-c-dropdown__toggle .pf-v5-c-title {
  color: var(--pf-v5-global--Color--light-100);
}

.oc-nav-header .pf-v5-c-dropdown__toggle .pf-v5-c-title.pf-m-md {
  font-size: 16px;
  font-family: var(--pf-v5-global--FontFamily--text);
}

.oc-nav-header .pf-v5-c-dropdown__toggle:before {
  border: none;
}

