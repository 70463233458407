@media (min-width: 769px) {
  .co-user-preference__form {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .co-user-preference__form {
    min-width: 650px;
  }
}

@media (min-width: 1200px) {
  .co-user-preference__form {
    max-width: 50%;
  }
}

