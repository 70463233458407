.skeleton-chart {
  height: inherit;
  position: relative;
  width: 100%;
}

.skeleton-chart::after {
  background-image: url("../../../images/skeleton-chart.svg");
  background-position: 0px bottom;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
}

.pf-v5-theme-dark .skeleton-chart::after {
  opacity: .2;
}

.skeleton-text {
  animation: loading-skeleton 1s ease-out .15s infinite alternate;
  background: var(--co-skeleton--Color);
  height: 24px;
  opacity: 0;
}

.skeleton-text::after {
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
}

.co-inventory-card__item {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.co-inventory-card__item:last-child {
  padding-bottom: 0rem;
}

.co-inventory-card__item-status {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.co-inventory-card__item-title {
  display: flex;
  margin-right: .5rem;
}

.co-inventory-card__status {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  margin-left: 1.8rem;
}

.co-inventory-card__status-icon--question {
  color: #d2d2d2;
}

.co-inventory-card__status-icon--progress {
  color: #6a6e73;
}

.co-inventory-card__accordion.pf-v5-c-accordion {
  border-bottom: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  box-shadow: none;
  padding: 0;
}

.co-inventory-card__accordion.pf-v5-c-accordion h5 {
  font-size: var(--pf-v5-c-card__body--FontSize);
  font-weight: var(--pf-v5-global--FontWeight--normal);
  margin: 0;
}

.co-inventory-card__accordion.pf-v5-c-accordion .co-inventory-card__accordion-toggle.pf-v5-c-accordion__toggle {
  --pf-v5-c-accordion__toggle--PaddingTop: 0;
  --pf-v5-c-accordion__toggle--PaddingRight: var(--pf-v5-c-card--child--PaddingRight);
  --pf-v5-c-accordion__toggle--PaddingBottom: 0;
  --pf-v5-c-accordion__toggle--PaddingLeft: 0;
  border-left: none;
}

.co-inventory-card__accordion.pf-v5-c-accordion .co-inventory-card__accordion-toggle.pf-v5-c-accordion__toggle .pf-v5-c-accordion__toggle-text {
  width: 100%;
}

.co-inventory-card__accordion.pf-v5-c-accordion .co-inventory-card__accordion-body.pf-v5-c-accordion__expanded-content {
  --pf-v5-c-accordion__expanded-content-body--PaddingTop: 1rem;
  --pf-v5-c-accordion__expanded-content-body--PaddingRight: var( --pf-v5-c-card--child--PaddingRight );
  --pf-v5-c-accordion__expanded-content-body--PaddingBottom: 1rem;
  --pf-v5-c-accordion__expanded-content-body--PaddingLeft: var( --pf-v5-c-card--child--PaddingRight );
  --pf-v5-c-accordion__expanded-content-body--expanded-content-body--PaddingTop: 1rem;
  border-left: none;
}

.skeleton-inventory {
  animation: loading-skeleton 1s ease-out .15s infinite alternate;
  background: var(--co-skeleton--Color);
  height: 20px;
  width: 20px;
  opacity: 0;
  margin-right: .5rem;
}

.skeleton-inventory::after {
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
}

