.co-app-launcher {
  ul {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }

  .pf-v5-c-app-launcher__menu-item-external-icon {
    --pf-v5-c-app-launcher__menu-item-external-icon--Color: var(--pf-v5-c-menu__item-action--Color);

    opacity: 1;
  }
}

.co-cluster-menu {
  max-width: 80vw;

  @media (min-width: $pf-v5-global--breakpoint--md) {
    max-width: 90vw;
  }
}

.co-cluster-selector {
  display: inline-grid !important;
  grid-template-columns: 1fr auto;
  max-width: 140px !important;

  @media (min-width: 360px) {
    max-width: 180px !important;
  }

  @media (min-width: $screen-xs) {
    max-width: 300px !important;
  }

  @media (min-width: $screen-lg) {
    max-width: 400px !important;
  }

  // PatternFly does not fully support <MenuToggle isFullHeight> styling in <Masthead>
  &::before {
    border: 1px solid var(--pf-v5-global--palette--black-800) !important;
    border-bottom: 0 !important;
    border-top: 0 !important;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    .pf-v5-theme-dark & {
      border-color: var(--pf-v5-global--palette--black-700) !important;
    }
  }
}

.co-masthead-icon {
  font-size: $pf-v5-header-icon-fontsize;
}

.co-username {
  max-width: 140px !important; // PF4 does not limit username length (upstream bug)
  overflow-x: hidden;
  text-overflow: ellipsis;
  @media (min-width: 855px) {
    max-width: 225px !important;
  }
  @media (min-width: $screen-md-min) {
    max-width: 275px !important;
  }
  @media (min-width: $screen-lg-min) {
    max-width: 300px !important;
  }
}

.pf-v5-c-masthead__content .pf-v5-c-notification-badge {
  --pf-v5-c-notification-badge--m-unread--hover--after--BackgroundColor: transparent;
}
