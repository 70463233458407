.co-example-docker-command__popover {
  width: 600px;
}

.co-images-stream-tag-timeline {
  font-size: 15px;
  margin: 20px $pf-v5-global-gutter--md;
  padding-left: 0;
  width: auto;
  @media (max-width: $screen-xs-max) {
    margin: 20px 10px;
  }
  li {
    height: auto;
    list-style: none;
    position: relative;
  }
}

.co-images-stream-tag-timeline__circle-icon,
.co-images-stream-tag-timeline__square-icon {
  color: var(--pf-v5-global--BorderColor--100);
  left: 8px;
  position: relative;
}

.co-images-stream-tag-timeline__info {
  background-color: var(--pf-v5-global--BackgroundColor--200);
  border-radius: 3px;
  line-height: 30px;
  margin: 8px 0 24px 40px;
  padding: 20px;
  width: 100%;
  & > div:first-of-type {
    font-size: var(--pf-v5-global--FontSize--sm);
    padding-bottom: 16px;
  }
}

.co-images-stream-tag-timeline__item-row {
  display: flex;
  margin: 2px 0;
}

.co-images-stream-tag-timeline__line {
  border: var(--pf-v5-global--BorderWidth--md) solid var(--pf-v5-global--BorderColor--100);
  left: 14px;
  position: relative;
}

.co-images-stream-tag-timeline__timestamp {
  margin-left: 35px;
}
