.oc-pinned-resource.pf-v5-c-nav__item {
  --pf-v5-c-nav__link--PaddingRight: 0;
  display: flex;
}

.oc-pinned-resource.pf-v5-c-nav__item .pf-v5-c-nav__link {
  display: block;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.oc-pinned-resource.pf-v5-c-nav__item .pf-v5-c-nav__link:hover {
  --pf-v5-c-nav__section-title--PaddingRight: 30px;
}

.oc-pinned-resource.pf-v5-c-nav__item .pf-v5-c-nav__link:hover .oc-pinned-resource__unpin-button .oc-pinned-resource__delete-icon,
.oc-pinned-resource.pf-v5-c-nav__item .pf-v5-c-nav__link:hover .oc-pinned-resource__unpin-button .oc-pinned-resource__drag-icon,
.oc-pinned-resource.pf-v5-c-nav__item .pf-v5-c-nav__link:hover .oc-pinned-resource__drag-button .oc-pinned-resource__delete-icon,
.oc-pinned-resource.pf-v5-c-nav__item .pf-v5-c-nav__link:hover .oc-pinned-resource__drag-button .oc-pinned-resource__drag-icon {
  opacity: 1;
}

.oc-pinned-resource.pf-v5-c-nav__item .oc-pinned-resource__unpin-button {
  background-color: var(--pf-v5-c-nav--m-dark__item--BackgroundColor);
  color: var(--pf-v5-c-nav__link--after--BorderColor);
  padding-bottom: 0;
  padding-top: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.pf-m-current .oc-pinned-resource.pf-v5-c-nav__item .oc-pinned-resource__unpin-button {
  background-color: var(--pf-v5-c-nav--m-dark__item--m-current--BackgroundColor);
}

.oc-pinned-resource.pf-v5-c-nav__item .oc-pinned-resource__unpin-button .oc-pinned-resource__delete-icon {
  opacity: 0;
}

.oc-pinned-resource.pf-v5-c-nav__item .oc-pinned-resource__drag-button {
  background-color: var(--pf-v5-c-nav--m-dark__item--BackgroundColor);
  color: var(--pf-v5-c-nav__link--after--BorderColor);
  padding: 0;
  cursor: move;
  width: 14px;
  position: absolute;
  left: 4px;
  top: 0;
  bottom: 0;
}

.pf-m-current .oc-pinned-resource.pf-v5-c-nav__item .oc-pinned-resource__drag-button {
  background-color: var(--pf-v5-c-nav--m-dark__item--m-current--BackgroundColor);
}

.oc-pinned-resource.pf-v5-c-nav__item .oc-pinned-resource__drag-button .oc-pinned-resource__drag-icon {
  opacity: 0;
}

.oc-pinned-resource--dragging {
  outline: 1px dashed var(--pf-v5-global--Color--400);
  overflow: hidden;
  background-color: var(--pf-v5-global--Color--200) !important;
}

.oc-pinned-resource--dragging div {
  opacity: .5;
}

