.ocs-badge {
  &__inline {
    background-color: var(--pf-v5-global--BackgroundColor--200);
    border: 1px solid var(--pf-v5-global--BorderColor--light-100);
  }
}

.ocs-preview-badge {
  &.pf-v5-c-label {
    --pf-v5-c-label--BackgroundColor: #d93f00;
    --pf-v5-c-label--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
    --pf-v5-c-label__content--before--BorderWidth: 0;
    --pf-v5-c-label__content--before--BorderColor: transparent;
    --pf-v5-c-label__content--Color: var(--pf-v5-global--Color--light-100);
    font-family: var(
      --pf-v5-global--FontFamily--text
    ); // Use RedHatText even when inside an <h1>
  }
}
