.dps-resource-item__resource-status {
  padding-left: var(--pf-v5-global--spacer--sm);
  white-space: nowrap;
}

.dps-resource-item__resource-status-badge {
  vertical-align: middle;
}

.dps-resource-item__resource-status-badge--alt {
  vertical-align: baseline;
}

.dps-resource-item__resource-status-badge .dps-icon-and-text__icon {
  top: 2px;
}

