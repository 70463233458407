@keyframes ocs-spotlight-expand {
  0% {
    opacity: 1;
  }

  100% {
    outline-offset: 21px;
    outline-width: 12px;
    opacity: 0;
  }
}

@keyframes ocs-spotlight-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes ocs-spotlight-fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ocs-spotlight {
  pointer-events: none;
  position: absolute;
}

.ocs-spotlight__with-backdrop {
  mix-blend-mode: hard-light;
}

.ocs-spotlight__element-highlight-noanimate {
  border: var(--pf-v5-global--BorderWidth--xl) solid var(--pf-v5-global--palette--blue-100);
  background-color: var(--pf-v5-global--palette--black-500);
  z-index: 9999;
}

.ocs-spotlight__element-highlight-animate {
  animation: .4s ocs-spotlight-fade-in 0s ease-in-out,5s ocs-spotlight-fade-out 12.8s ease-in-out;
  animation-fill-mode: forwards;
  pointer-events: none;
  position: absolute;
  opacity: 0;
  outline: 4px solid var(--pf-v5-global--palette--blue-200);
  outline-offset: 4px;
}

.ocs-spotlight__element-highlight-animate::after {
  animation-fill-mode: forwards;
  animation: 1.2s ocs-spotlight-expand 1.6s ease-out;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  outline: 4px solid var(--pf-v5-global--palette--blue-200);
  outline-offset: 4px;
}

