.skeleton-chart {
  height: inherit;
  position: relative;
  width: 100%;
}

.skeleton-chart::after {
  background-image: url("../../../images/skeleton-chart.svg");
  background-position: 0px bottom;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
}

.pf-v5-theme-dark .skeleton-chart::after {
  opacity: .2;
}

.skeleton-text {
  animation: loading-skeleton 1s ease-out .15s infinite alternate;
  background: var(--co-skeleton--Color);
  height: 24px;
  opacity: 0;
}

.skeleton-text::after {
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
}

:root {
  --pf-v5-global--FontSize--md: 14px;
  --pf-global--FontSize--md: 14px;
  --pf-v5-global--FontSize--sm: 13px;
  --pf-global--FontSize--sm: 13px;
}

.co-activity-card__body {
  max-height: 30rem;
  min-height: 10rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.co-activity-card__ongoing-body .co-activity-item__ongoing:last-of-type {
  border-bottom: none;
}

.co-activity-card__ongoing-title {
  border-bottom: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  padding: .2rem var(--pf-v5-c-card--child--PaddingRight) .2rem var(--pf-v5-c-card--child--PaddingLeft);
}

.co-activity-card__recent-more-link {
  padding: 0 var(--pf-v5-c-card--child--PaddingRight) var(--pf-v5-c-card--child--PaddingBottom) var(--pf-v5-c-card--child--PaddingLeft);
}

.co-activity-card__recent-accordion.pf-v5-c-accordion {
  box-shadow: none;
  padding-bottom: 0;
  padding: .75rem 0;
}

.co-activity-card__recent-accordion.pf-v5-c-accordion h5 {
  font-family: var(--pf-v5-global--FontFamily--text);
  font-size: var(--pf-v5-c-card__body--FontSize);
  margin: 0;
}

.co-activity-card__recent-accordion.pf-v5-c-accordion .co-recent-item__content .pf-v5-c-accordion {
  --pf-v5-c-accordion__expanded-content-body--PaddingRight: var( --pf-v5-c-card--child--PaddingRight );
  --pf-v5-c-accordion__expanded-content-body--PaddingLeft: var( --pf-v5-c-card--child--PaddingLeft );
}

.co-activity-card__recent-accordion.pf-v5-c-accordion .co-recent-item__content .pf-v5-c-accordion__expandable-content-body {
  padding-inline-start: var(--pf-v5-c-card--child--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-card--child--PaddingRight);
}

.co-activity-card__recent-accordion.pf-v5-c-accordion .co-recent-item__toggle.pf-v5-c-accordion__toggle {
  --pf-v5-c-accordion__toggle--PaddingRight: var(--pf-v5-c-card--child--PaddingRight);
  --pf-v5-c-accordion__toggle--PaddingLeft: var(--pf-v5-c-card--child--PaddingLeft);
  line-height: 18px;
}

.co-activity-card__recent-accordion.pf-v5-c-accordion .co-recent-item--warning {
  border-left-color: var(--pf-v5-global--warning-color--100);
}

.co-activity-card__recent-accordion.pf-v5-c-accordion .pf-v5-c-accordion__toggle .pf-v5-c-accordion__toggle-text,
.co-activity-card__recent-accordion.pf-v5-c-accordion .pf-v5-c-accordion__toggle:active .pf-v5-c-accordion__toggle-text,
.co-activity-card__recent-accordion.pf-v5-c-accordion .pf-v5-c-accordion__toggle:focus .pf-v5-c-accordion__toggle-text {
  font-weight: var(--pf-v5-global--FontWeight--normal) !important;
}

.co-activity-card__recent-actions svg {
  vertical-align: -0.125rem !important;
}

.co-activity-card__recent-list {
  padding-bottom: 0 !important;
}

.co-activity-card__recent-title {
  border-bottom: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: .2rem var(--pf-v5-c-card--child--PaddingRight) .2rem var(--pf-v5-c-card--child--PaddingLeft);
}

.co-activity-item {
  display: flex;
}

.co-activity-item__ongoing {
  margin: 1rem var(--pf-v5-c-card--child--PaddingRight) 1rem var(--pf-v5-c-card--child--PaddingLeft);
}

.co-activity-item__progress.pf-v5-c-progress {
  --pf-v5-c-progress--GridGap: 0;
  padding-bottom: .2rem;
}

.co-activity-item__icon {
  padding-right: var(--pf-v5-global--spacer--xs);
}

.co-recent-item__content-header {
  display: flex;
  justify-content: space-between;
  white-space: pre;
}

.co-recent-item__content-message {
  color: var(--pf-v5-global--Color--100);
  margin-top: .5rem;
  word-wrap: break-word;
}

.co-recent-item__content-resourcelink {
  display: block;
  flex: 2 0 0;
  white-space: nowrap;
}

.co-recent-item__content-resourcelink .co-resource-item__resource-name {
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.co-recent-item__icon {
  height: 18px !important;
  margin-right: var(--pf-v5-global--spacer--xs);
  min-width: 18px;
}

.co-recent-item__icon--warning {
  font-size: 1.1rem;
}

.co-recent-item__title {
  align-items: center;
  display: flex;
}

.co-recent-item__title-message {
  align-items: center;
  display: flex;
  overflow: hidden;
}

.co-recent-item__title-message-text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.co-recent-item__title-timestamp {
  min-width: 60px;
  padding-right: .3rem;
  text-align: left;
}

.skeleton-activity__dashboard {
  animation: loading-skeleton 1s ease-out .15s infinite alternate;
  height: 24px;
  margin-bottom: 1em;
  opacity: 0;
  width: 100%;
}

.skeleton-activity__dashboard::after {
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
}

.skeleton-activity__dashboard:last-of-type {
  margin-bottom: 0;
}

.skeleton-activity__dashboard::after {
  background-image: linear-gradient(var(--co-skeleton--Color) 24px, transparent 0),radial-gradient(circle 12px at center, var(--co-skeleton--Color) 100%, transparent 0);
  background-position: 45px 0,0 0;
  background-size: auto,24px 24px;
}

