.service-ips {
  .co-ip-header {
    padding: 10px 0 5px 20px;
  }

  .co-ip-row {
    // Collapse borders when multiple consecutive rows.
    margin-top: -1px;
    padding: 20px 0 5px 20px;
    border: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--300);

    .ip-name {
      margin-bottom: 5px;
    }
  }
}

.port-list {
  padding-left: 0;
  list-style: none;
}
