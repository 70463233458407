.co-overlay {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  cursor: default;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: $zindex-modal;
}

.modal-dialog {
  --os-modal-dialog--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);

  background: var(--os-modal-dialog--BackgroundColor);

  :where(.pf-v5-theme-dark) & {
    --os-modal-dialog--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  }
}

.modal-body {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  padding: 0;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.modal-body-border {
  border-top: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--300);
}

.modal-body-content {
  height: 100%;
  padding: 0 var(--pf-v5-global--spacer--xl) var(--pf-v5-global--spacer--xl);
  p {
    margin-bottom: var(--pf-v5-c-content--MarginBottom);
  }
}

.modal-content {
  background-clip: padding-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  // Remove focus outline from opened modal
  outline: 0;
  position: relative;
}

.modal-dialog {
  margin: var(--pf-v5-global--spacer--md);
  max-width: calc(100% - var(--pf-v5-global--spacer--xl));
  outline: 0;
  position: relative;
  width: 100%;
  @media (min-width: $pf-v5-global--breakpoint--md) {
    margin: 30px auto;
    &:not(.modal-lg) {
      max-width: 600px;
    }
  }
  @media (min-width: $pf-v5-global--breakpoint--xl) {
    &.modal-lg {
      // Manually set to match --pf-v5-c-modal-box--m-lg--lg--MaxWidth because the PF variable is scoped to .pf-v5-c-modal-box
      max-width: 70rem;
    }
  }
}

.modal-footer {
  margin-top: 0;
  padding: var(--pf-v5-global--spacer--md) var(--pf-v5-global--spacer--xl)
    var(--pf-v5-global--spacer--xl) var(--pf-v5-global--spacer--xl);
}

.modal-header {
  padding: var(--pf-v5-global--spacer--xl) var(--pf-v5-global--spacer--xl)
    var(--pf-v5-global--spacer--lg);
}

.modal__inline-resource-link {
  margin: 0;
}

.toleration-modal__field,
.taint-modal__field {
  padding-right: 0;
}

.toleration-modal__field,
.taint-modal__input {
  margin-bottom: 5px;
  @media (max-width: $screen-sm-max) {
    margin-bottom: 10px;
  }
}

.toleration-modal__heading,
.taint-modal__heading {
  margin-bottom: 5px;
}

.toleration-modal__row,
.taint-modal__row {
  margin-bottom: 15px;
  @media (max-width: $screen-sm-max) {
    margin-bottom: 24px;
  }
}

.modal-paragraph {
  margin-bottom: var(--pf-v5-c-content--MarginBottom);
}
