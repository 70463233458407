.skeleton-chart {
  height: inherit;
  position: relative;
  width: 100%;
}

.skeleton-chart::after {
  background-image: url("../../../images/skeleton-chart.svg");
  background-position: 0px bottom;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
}

.pf-v5-theme-dark .skeleton-chart::after {
  opacity: .2;
}

.skeleton-text {
  animation: loading-skeleton 1s ease-out .15s infinite alternate;
  background: var(--co-skeleton--Color);
  height: 24px;
  opacity: 0;
}

.skeleton-text::after {
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
}

.co-status-card__alerts-body {
  border-top: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  max-height: 19rem;
  min-height: 6rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.co-status-card__alerts-body .pf-v5-c-empty-state {
  --pf-v5-c-empty-state__icon--MarginBottom: 0 !important;
}

.co-status-card__alert-item--loading {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.co-status-card__alerts-msg.pf-v5-c-empty-state {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.co-status-card__alerts-icon.pf-v5-c-empty-state__icon {
  --pf-v5-c-empty-state__icon--Color: $pf-v5-color-black-400;
}

.co-status-card__alert-item {
  display: flex;
  padding-bottom: .5rem;
  padding-left: var(--pf-v5-c-card--child--PaddingLeft);
  padding-right: var(--pf-v5-c-card--child--PaddingRight);
}

.co-status-card__alert-item-icon {
  display: flex;
}

.co-status-card__alert-item-message {
  display: flex;
  flex-direction: column;
}

.co-status-card__alert-item-more {
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  padding-left: var(--pf-v5-c-card--child--PaddingLeft);
}

.co-status-card__alert-item-text {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: small;
}

.co-status-card__alert-item-header {
  margin-left: 1rem;
  font-weight: bold;
  color: var(--pf-v5-global--Color--black);
}

.co-status-card__alert-item-doc-link {
  margin-left: 1rem;
  margin-top: .2rem;
  width: fit-content;
}

.co-health-card__alert-item-timestamp {
  white-space: nowrap;
  font-size: smaller;
  margin-bottom: .5rem;
}

.co-status-card__alert-item:first-of-type {
  padding-top: var(--pf-v5-c-card--first-child--PaddingTop);
}

.co-status-card__alert-item:last-of-type {
  padding-bottom: var(--pf-v5-c-card--child--PaddingBottom);
}

.co-status-card__health-body {
  display: flex;
  flex-wrap: wrap;
  padding-left: var(--pf-v5-c-card--child--PaddingLeft);
  padding-right: var(--pf-v5-c-card--child--PaddingRight);
  padding-bottom: var(--pf-v5-c-card__title--not--last-child--PaddingBottom);
}

.co-status-card__health-item {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  padding-right: 3rem;
}

.co-status-card__health-item-text {
  word-break: break-word;
  display: block;
  margin-left: 1rem;
  overflow-wrap: break-word;
  white-space: pre-line;
}

.co-status-card__health-item:last-of-type {
  padding-right: 0;
}

.co-status-card__popup {
  padding: 0;
}

.skeleton-health {
  animation: loading-skeleton 1s ease-out .15s infinite alternate;
  border-radius: 50px;
  background: var(--co-skeleton--Color);
  height: 24px;
  opacity: 0;
  width: 24px;
}

.skeleton-health::after {
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
}

.skeleton-status-alerts {
  animation: loading-skeleton 1s ease-out .15s infinite alternate;
  height: 50px;
  margin-bottom: 1em;
  opacity: 0;
  width: 100%;
}

.skeleton-status-alerts::after {
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
}

.skeleton-status-alerts:last-of-type {
  margin-bottom: 0;
}

.skeleton-status-alerts::after {
  background-image: linear-gradient(var(--co-skeleton--Color) 24px, transparent 0),linear-gradient(var(--co-skeleton--Color) 24px, transparent 0),radial-gradient(circle 12px at center, var(--co-skeleton--Color) 100%, transparent 0);
  background-position: 45px 0,45px 24px,0 10px;
  background-size: 80px 15px,75%,24px 24px;
}

