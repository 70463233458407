.odc-item-selector-filter {
  display: flex;
  padding-bottom: var(--pf-v5-global--spacer--md);
}

.odc-item-selector-filter__input {
  max-width: 20em;
}

.odc-item-selector-filter__count {
  color: var(--pf-v5-global--Color--200);
  margin-left: auto;
}

.odc-item-selector-field {
  display: inline-flex;
  flex-direction: column;
  flex-flow: wrap;
  background: var(--pf-v5-global--BackgroundColor--200);
  padding: 4px;
}

.odc-item-selector-field__scrollbar {
  max-height: 260px;
  overflow-y: auto;
}

.odc-item-selector-field__success-icon {
  color: var(--pf-v5-global--palette--green-700);
  font-size: var(--pf-v5-global--FontSize--md);
  margin-left: var(--pf-v5-global--spacer--lg);
}

