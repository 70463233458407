.co-copy-to-clipboard {
  position: relative; // prevents double scrollbars when used with reveal/hide value toggle
}

.co-copy-to-clipboard__stacktrace-width-height {
  display: flex;
  .co-copy-to-clipboard {
    width: 100%;
  }
  .co-copy-to-clipboard__text {
    max-height: 25vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}
