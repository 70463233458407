// Base element styles.
// Please DO NOT add rules of this type.

//instead of &.foo
//
//
// @at-root .foo#{&}

:where(:not([class*="pf-v5-c-"])) {
  @at-root dd#{&} {
    margin-bottom: var(--pf-v5-global--spacer--lg);
  }

  @at-root dd:last-child#{&} {
    margin-bottom: 0;
  }

  @at-root dl#{&} {
    margin: 0;
  }

  // match pf4 styling / table col head styling
  @at-root dt#{&} {
    font-weight: var(--pf-v5-global--FontWeight--bold);
  }

  @at-root fieldset#{&} {
    border: 0;
  }

  @at-root input[type='number']#{&} {
    -webkit-appearance: textfield;
    appearance: textfield;
    max-width: 100px;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }

  @at-root input[type='radio']#{&} {
    margin-right: 7px;
  }

  @at-root :is(ul, ol)#{&} {
    padding-left: 20px;
  }
}
