.co-cloud-shell-drawer {
  &__heading {
    padding-left: var(--pf-v5-global--spacer--md);
  }
  &__body {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    & .pf-v5-c-tabs {
      flex-shrink: 0;
    }
  }
}
