.co-create-route {
  &__alert {
    margin-bottom: 0;
  }
}

.co-create-route__add-service-btn {
  margin-bottom: 15px;
}

.co-create-route__weight-label {
  display: block;
}
